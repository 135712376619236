import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import ConfirmBox from '../../DesignSystem/ConfirmBox';
import { deleteClaim, openDeleteModal } from '../../actions/action.getClaims';
import TrashIcon from '@atlaskit/icon/glyph/trash'

export const DeleteClaim = (props) => {

    const dispatch = useDispatch();
    const {
        selectedClaim
    } = props;

    const [ openModal, setOpenModal ] = useState(false);

    const deleteSelectedClaim = () => {
        const payload = {
            systemIdentifier: selectedClaim
        }

        dispatch(deleteClaim(payload))
        dispatch(openDeleteModal(false))
        setOpenModal(false)
    }

    return (
        <div>
            <div onClick={() => {
                dispatch(openDeleteModal(true))
                setOpenModal(true)
            }}>
                <TrashIcon
                    type="small"
                    primaryColor="red"
                />
            </div>
            {
                openModal ?
                <ConfirmBox
                    content={`Are you sure you want to delete the claim ${selectedClaim}?`}
                    isOpen={openModal}
                    toggle={() => {
                        dispatch(openDeleteModal(!openModal))
                        setOpenModal(!openModal)
                    }}
                    handleOk={deleteSelectedClaim}
                ></ConfirmBox>
                : <div></div>
            }

        </div>
    )
}