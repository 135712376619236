import React from 'react';
import ReactExport from 'react-export-excel';

import { Col } from 'reactstrap'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const DownloadClaimsTemplate = ({ data, children }) => {

  /**
   * Check necessary fields to claims' template
   */
  const {
    categories
  } = data;

  return (
    <Col className="headerItemCol" style={{ marginRight: '5px' }}>
        <ExcelFile
          element={children}
          filename={`Audit Report Template`}
        >
        <ExcelSheet data={[]} name="Claims">
            <ExcelColumn label="Claim Number"/> 
            <ExcelColumn label="Processed Date"/>
            <ExcelColumn label="Audit Date"/>
            <ExcelColumn label="Group"/>
            <ExcelColumn label="Examiner"/>
            <ExcelColumn label="Auditor"/>
            <ExcelColumn label="Notes"/>
            <ExcelColumn label="Total Charge"/>
            <ExcelColumn label="Total Payment"/>
            <ExcelColumn label="Financial Discrepancy"/>
            <ExcelColumn label="Error Type"/>
            {categories?.map((column, index) => {
              return (
                <ExcelColumn key={index} label={column.description} />
              );
            })}
        </ExcelSheet>
        </ExcelFile>
    </Col>
  );
};

export default DownloadClaimsTemplate;