export const urlConstants = {
  BASE_URL: process.env.REACT_APP_API_HOST || '',
  BASE_URL_HANDLER: process.env.REACT_APP_API_LAMBDA_HOST || '',
  QUERY: '/query',
  COMMANDS: '/commands'
};

export const uriConstants = {
  CLAIM_SEARCH_URL: "/claims",
  CLAIM_API: "/claim",
  CLAIM_DETAIL: "/claimDetail",
  CLAIM_ACTION: "/claimAction",
  UPDATE_CATEGORY_ITEM: "/categoryItem",
  CREATE_CLAIM: "/newClaim",
  CREATE_USER_URL: "/createuser",
  SEND_CODE: "/sendaccesscode",
  VERIFY_CODE: "/verifyaccesscode",
  RESET_PWD: "/resetpassword",
  VERIFY_USER_EMAIL: "/verifyuseremail",
  GET_USER: "/GetUsers",
  GET_CATEGORIES_SUBCATEGORIES: "/categories",
  UPDATE_CLAIM_CATEGORIES: "/claimCategories",
  GET_CLAIMS_BY_ASSIGNEE: '/assignedClaims',
  GET_CLAIMS_BY_STATUS: '/claimsWithStatus',
  DELETE_CLAIM: '/deleteClaim',
  GET_PRESIGNED_URL: '/getPresignedUrl',
  PARSE_CLAIMS_FILE: '/parseClaimsFile',
  IMPORT_FILE_CLAIMS: '/importClaimsFiles',
  GET_ALL_AUDIT_OPTIONS: '/getAllOptions',
  GET_AUDIT_OPTIONS: '/auditOptions', //'/getOptions',
  ADD_AUDIT_OPTION: '/addOption',
  GET_ALL_CLAIMS: '/getAllClaims'
};

