import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, FormGroup, FormText, Input, Button, Col } from 'reactstrap'

import ConfirmBox from '../../DesignSystem/ConfirmBox'
import { ErrorModal } from '../../DesignSystem/ErrorModal';
import { Toast } from "../../DesignSystem/Toast";
import { importFileClaims, parseImportFile, clearFileData, getClaims } from '../../actions/action.getClaims';

const xlsx = require('xlsx');

export const ImportClaims = (props) => {

    const {
      importFeature
    } = props.parentProps;

    const dispatch = useDispatch();
    const [claimsFileName, setClaimsFileName] = useState(null);
    const [claimsFileType, setClaimsFileType] = useState(null);
    const [claimsFile, setClaimsFile] = useState(null);
    const [isValidFile, setIsValidFile] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [openValidateModal, setOpenValidateModal] = useState(false);

    const clearClaimFileData = () => {
        setClaimsFileName(null)
        setClaimsFileType(null)
        setClaimsFile(null)
        setIsValidFile(false)
        setOpenValidateModal(false)
    }

    const getFileExtension = (name) => {
        const lastDot = name.lastIndexOf('.');
        const extension = name.substring(lastDot + 1);
        return extension;
    }

    const getFileSize = (file) => {
        const reader = new FileReader();
        reader.onload = (evt) => {
            const bstr = evt.target.result;
            const wb = xlsx.read(bstr, { type: "binary" });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const range = xlsx.utils.decode_range(ws["!ref"]);
            const num_rows = range.e.r - range.s.r + 1;
            validateNumberOfRows(num_rows)
        };
        reader.readAsBinaryString(file);
    }

    const validateImportedFile = (event) => {
      if (!event || !event.target || !event.target.files || event.target.files.length === 0) return;

      const file = event.target.files[0];
      const name = file.name;
      const extension = getFileExtension(name);

      if (extension === 'xlsx' || extension === 'csv') {
        updateClaimFileData(file, name, extension)
      } else {
        setErrorMessage('Invalid file extension. Please enter a .xlsx or .csv file.')
        clearClaimFileData();
      }
    }
    
    const validateNumberOfRows = (rows) => {
      const maxRows = 100000;
        
      if (rows <= maxRows) {
        setOpenValidateModal(true)
        setIsValidFile(true)
      } else {
        setErrorMessage('The uploaded file is over the size limit. Please upload a file with less than 100,000 rows.')
      }
    }

    const parseClaimsFile = async () => {
      const data = await new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onload = () => {
          resolve(fr.result);
        };
        fr.onerror = reject;
        fr.readAsArrayBuffer(claimsFile);
      });

      setOpenValidateModal(false);
      dispatch(parseImportFile({ fileData: data, fileName: claimsFileName, username: sessionStorage.getItem('username') }));
    }

    const submitClaimsFile = async () => {
      const importedClaims = importFeature?.importedClaims;
      if (importedClaims && importedClaims.length > 0) {
        dispatch(importFileClaims({ records: importedClaims }));
        dispatch(clearFileData());
      }
    }

    const updateClaimFileData = (file, name, extension) => {
      getFileSize(file);
      setClaimsFileName(name);
      setClaimsFileType(extension);
      setClaimsFile(file);
    }

    return (
      <Col className='headerItemCol'>
          <Toast
            isOpen={importFeature?.isFilesImported}
            duration={2000}
            message="Claims successfully imported!"
            onOpenedFunc={() => dispatch(getClaims())}
          >
          </Toast>
          <Form>
              <FormGroup>
                  <Button 
                      appearance="primary" 
                      className="createClaimButton buttonSizeSm" 
                      onClick={() => document.getElementById('input-importClaims').click()}> 
                          Import Claims 
                      </Button>
                  <Input
                      id='input-importClaims'
                      type='file'
                      name='importClaims'
                      accept=".xlsx, .csv"
                      style={{display:'none'}}
                      onChange={(event) => validateImportedFile(event)}
                      onClick={(event) => event.target.value = null}
                  />
                  <FormText>
                      Only .xlsx and .csv files
                  </FormText>
              </FormGroup>

              <ConfirmBox
                  isOpen={openValidateModal && !!isValidFile}
                  content={`Do you want to validate the following file: ${claimsFileName}?`}
                  handleOk={() => parseClaimsFile()}
                  toggle={() => clearClaimFileData()}
              ></ConfirmBox>

              <ConfirmBox
                  isOpen={importFeature?.isFileValid && importFeature?.importedClaims?.length > 0}
                  content={`The ${claimsFileName} file is valid! Do you want to import the claims?`}
                  handleOk={() => submitClaimsFile()}
                  toggle={() => dispatch(clearFileData())}
              ></ConfirmBox>

              <ErrorModal
                  apiError={errorMessage}
                  closeError={() => {
                      setErrorMessage(null)
                      clearClaimFileData()
                  }}
              ></ErrorModal>
          </Form>
      </Col>
    )
}