import React, { PureComponent } from "react";
import { connect } from "react-redux";
import GetClaimsComponent from "../../components/getClaims";
import ClaimDetail from "../../components/claimDetail";
import { clearReducer } from "../../actions/action.getClaims";

class GetClaims extends PureComponent {
  componentDidMount() {
    this.props.clearReducer();
  }
  render() {
    return this.props.selectedClaims && !this.props.isDeleteModalOpen ? (
      <ClaimDetail parentProps={this.props} />
    ) : (
      <GetClaimsComponent parentProps={this.props} />
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {clearReducer : () => dispatch(clearReducer())};
};

const mapStateToProps = (state) => {
  return {
    ...state,
    claims: state.getClaims.claims,
    optionsData: state.auditOptions.optionsData,
    groups: state.auditOptions.groups,
    examiners: state.auditOptions.examiners,
    auditors: state.auditOptions.auditors,
    categories: state.auditOptions.categoriesSubCategories,
    importFeature: state.getClaims.importFeature,
    loading: state.getClaims.loading,
    searchError: state.getClaims.searchError,
    searchField: state.getClaims.searchField,
    selectedClaims: state.getClaims.selectedClaims,
    categoriesSubCategories: state.auditOptions.categoriesSubCategories,
    isDeleteModalOpen: state.getClaims.isDeleteModalOpen
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GetClaims);
