import React, { Component } from 'react';
import { 
    Modal, 
    ModalBody, 
    ModalHeader,
    Row
} from 'reactstrap';

import Button from "@atlaskit/button";
import { withRouter } from 'react-router-dom';

class SignOutModal extends Component {

  constructor(props) {
    super(props);
  }

  logOut() {
    this.props.history.push("/logout");
  }

  render() {
    return (
        <Modal
            isOpen={this.props.expiredToken}
            centered={true}
            size="lg"
        >
            <ModalHeader> Session Expired </ModalHeader>
            <ModalBody>
                <Row style={{ display: 'flex', justifyContent: 'center' }}>
                    Your session has expired. Please sign out, you will be redirected to the login page.
                </Row>
                <Row style={{ display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                    <Button onClick={() => this.logOut()}>
                        Sign Out
                    </Button>
                </Row>
            </ModalBody>
        </Modal>
    )
  }
}

export default withRouter(SignOutModal);