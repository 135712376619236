import React, { useState } from "react";
import {
    Button, 
    Container,
    Row,
    Col
} from 'reactstrap';
import { CreateClaimModal } from "./createClaimModal";
import { useDispatch } from "react-redux";

import {
    createNewClaim
} from "../../actions";
import { formatStringToComparison, checkSelectObject } from "../../utils/utils";

import "./scss/createClaim.scss"

export const CreateClaimComponent = (props) => {

    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(false);

    const submitClaim = (data) => {

        for (const [key, obj] of Object.entries(data)) {
            if (checkSelectObject(obj)) {
                data[key] = obj.value;
            }
        };

        data = {
            ...data,
            ...{
                'claimNumber': data.claimNumber.replace(/\s/g,''),
                'client#plan': `${data.client}#${data.plan}`,
                'systemIdentifier': data.claimNumber,
                'domainId': data.claimNumber,
                'examinerNameReporting': formatStringToComparison(data.examiner),
                'categoryItemList': "[]"
            }
        };

        return dispatch(createNewClaim(data))
    }

    return (
        <Col className="headerItemCol" style={{ marginRight: '5px' }}>
            <Button
                appearance="primary"
                className="createClaimButton buttonSizeSm"
                onClick={() => setOpenModal(true)}
            >
                Create Claim
            </Button>   
            <CreateClaimModal
                openModal={openModal}
                closeModal={() => setOpenModal(false)}
                submitClaim={submitClaim}
                parentProps={props.parentProps}
            >
            </CreateClaimModal>
        </Col>
    )
}