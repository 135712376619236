
import React from "react"
import moment from "moment";
import { handleValue } from '../utils/utils';
import { DeleteClaim } from '../components/getClaims/deleteClaim'

export const CLAIM_GRID = [
    {
      title: "Claim Number",
      sortable: true,
      key: "claimNumber",
      render: (value, row, index) => {
        return row.claimNumber;
      },
    },
    {
      title: "Processed Date",
      key: "claimProcessedDate",
      sortable: true,
      render: (value, row, index) => {
        return row.claimProcessedDate;
      },
    },
    {
      title: "Audit Date",
      key: "auditLockDate",
      sortable: true,
      render: (value, row, index) => {
        return row.auditLockDate;
      },
    },
    {
      title: "Group",
      key: "client",
      sortable: true,
      render: (value, row, index) => {
        return row.client;
      },
    },
    {
      title: "Examiner",
      key: "examiner",
      sortable: true,
      render: (value, row, index) => {
        return row.examiner;
      },
    },
    {
      title: "Auditor",
      key: "assignee",
      sortable: true,
      render: (value, row, index) => {
        return row.assignee;
      }
    },
    {
      title: "Audit Status",
      key: "auditStatus",
      sortable: true,
      render: (value, row, index) => {
        return row.auditStatus;
      },
    },
    {
      title: "Total Charge",
      key: "totalCharge",
      sortable: true,
      className: "text-center",
      render: (value, row, index) => {
        return `$${row.totalCharge ? row.totalCharge : 0 }`;
      },
    },
    {
      sortable: true,
      title: "Total Payment",
      key: "totalPayment",
      className: "text-center",
      //enableServerSideSorting: true,
      render: (value, row, index) => {
        return `$${row.totalPayment ? row.totalPayment : 0 }`;
      },
    },
    {
      sortable: true,
      title: "Financial Discrepancy",
      key: "monetaryValue",
      className: "text-center",
      render: (value, row, index) => {
        return `$${row.monetaryValue ? row.monetaryValue : 0 }`;
      },
    },
    {
      sortable: true,
      title: "Error Type",
      key: "errorType",
      render: (value, row, index) => {
        return row.errorType ? row.errorType : 'NA';
      }
    },
    {
      title: '',
      key: 'delete',
      sortable: false,
      clickable: false,
      render: (value, row, index) => {
        return (
          <DeleteClaim
            selectedClaim={row.systemIdentifier}
          ></DeleteClaim>
        )
      }
    }
  ];

  export const ACTION_HISTORY_GRID = [
    {
      title: "Date",
      sortable: true,
      key: "date",
      render: (value, row, index) => {
        return moment(row.date).format("YYYY-MM-DD");
      },
    },
    {
      title: "Performed By",
      key: "user",
      sortable: true,
      render: (value, row, index) => {
        return row.user;
      },
    },
    {
      sortable: true,
      title: "User Role",
      key: "userRole",
      //enableServerSideSorting: true,
      render: (value, row, index) => {
        return row.userRole;
      },
    },
    {
      title: "Assignee",
      key: "assignee",
      sortable: true,
      render: (value, row, index) => {
        return row.assignee;
      },
    },
    {
      title: "Status",
      key: "status",
      sortable: true,
      render: (value, row, index) => {
        return row.status;
      },
    },
    {
      title: "Agree/Disagree",
      key: "agreeDisagree",
      sortable: true,
      render: (value, row, index) => {
        return row.isAgree === null ? "" :  (row.isAgree ? "Agree" : "Disagree" );
      },
    },
    {
      title: "Action notes",
      key: "actionNotes",
      sortable: true,
      render: (value, row, index) => {
        return row.notes;
      },
    }
  ];

export const CALCULATION_GRID = [
  {
    title: "Procedural accuracy (%)",
    sortable: false,
    key: "proceduralAccuracy",
    render: (value, row, index) => {
      return row.proceduralAccuracy;
    }
  },
  {
    title: "Payment accuracy (%)",
    sortable: false,
    key: "paymentAccuracy",
    render: (value, row, index) => {
      return row.paymentAccuracy;
    }
  },
  {
    title: "Financial Accuracy (%)",
    sortable: false,
    key: "financialAccuracy",
    render: (value, row, index) => {
      return row.financialAccuracy;
    }
  }
]

export const REPORT_GRID = [
  {
    title: "Claim Number Audited",
    sortable: true,
    key: "claimNumber",
    render: (value, row, index) => {
      return row.claimNumber;
    },
  },
  {
    title: "Group Name",
    key: "client",
    sortable: true,
    render: (value, row, index) => {
      return row.client;
    },
  },
  {
    title: "Auditor Name",
    key: "assignee",
    sortable: true,
    render: (value, row, index) => {
      return row.assignee;
    },
  },
  {
    title: "Date Claim Processed by Examiner",
    key: "claimProcessStartDate",
    sortable: true,
    render: (value, row, index) => {
      return row.claimProcessStartDate;
    },
  }
];
