import React, { PureComponent ,Suspense } from "react";
import {
  Container,
  Nav,
  NavItem,
  Navbar,
  Collapse,
  NavbarBrand,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  
} from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import Auth from "@aws-amplify/auth";
import { connect } from "react-redux";
import "./../../assets/scss/header.scss";
import { userLogoutRequest } from "./../../actions";
import { clearReducer } from "./../../actions/action.getClaims";
import { clearAuditOptionsReducer } from "./../../actions/action.auditOptions"
import Avatar from  "react-avatar";


const tabs = [
  {
    key: "claims",
    name: "claims",
  },
  {
    key: "category",
    name: "category",
  },
  // {
  //   key: "options",
  //   name: "options"
  // }
];

const logoutTabs = [
  {
    key: "/",
    name: "Home",
  },
];
const style = { width: "67%" };

class Header extends PureComponent {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      activeTabKey: "claims",
      openMenu: false,
    };
  }

  tabClickHandler = (event) => {
    sessionStorage.removeItem("selectedTab");
    let key = event.currentTarget.dataset.key;
    this.setState({ activeTabKey: key });
    this.props.history.push(`/${key}`);
  };

  logOutUser = () => {
    Auth.signOut({ global: false })
      .then(() => {
        this.props.userLogoutRequest();
        this.props.clearReducer();
        this.props.clearAuditOptionsReducer();
        sessionStorage.clear();
        localStorage.clear();
        this.props.history.push("/");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  tabOutClickHandler = (key) => {
    const path = key === "/" ? "/" : "/" + key;
    this.props.history.push(path);
  };

  showOuterHeaderLinks = () => {
    return ["/forgetPassword", "signUp"].indexOf(this.props.match.path) === -1;
  };

  toggle = () => {
    this.setState({ ...this.state, openMenu: !this.state.openMenu });
  };


  render() {
    let login = localStorage.getItem('login');
    return (
      <>
        {this.props.match.path !== "/" && (
          <header className="header fixed-top">
            <Container>
              <Navbar color="light" light expand="md">
                <NavbarBrand href='/claims'>
                  <img
                    src="https://s3.amazonaws.com/centivo-email-templates/CENHEA_18623-1_LogoWithTrademark_RGB.png"
                    alt=""
                    className="logo-navbar"
                  />
                </NavbarBrand>

                {sessionStorage.getItem("loginClient") &&
                this.props.match.path !== "/" &&
                this.showOuterHeaderLinks() ? (
                  <>
                    <Navbar color="light" style={style}>
                      <Collapse navbar>
                        <Nav className="ml-auto margin-top-5" navbar>
                          <>
                            {tabs.map((tab) => {
                              return (
                                <NavItem
                                  key={tab.key}
                                  className={
                                    window.location.href.indexOf(tab.key) > -1
                                      ? "active"
                                      : ""
                                  }
                                  data-key={tab.key}
                                  onClick={this.tabClickHandler}
                                >
                                  <Link
                                    style={  {pointerEvents:  tab.key === 'category' && localStorage.getItem('login') === 'EXAMINER' ? 'none' : !login ? 'none' :  ''}}
                                    to={
                                      "/" +
                                      tab.key +
                                      "#" +
                                      sessionStorage.getItem("param")
                                    }
                                    className="nav-link categoryLink"
                                  >
                                    {tab.name}
                                  </Link>
                                </NavItem>
                              );
                            })}
                          </>

                          <div style={{ height: "45px !important" }}>
                            <Dropdown
                              isOpen={this.state.openMenu}
                              toggle={this.toggle}
                            >
                             <DropdownToggle
                                color="primary"
                                className="profileToggle"
                              >
                                <Avatar color='#00a79d' round={true} size={40} name={localStorage.getItem("login")} />
                                 
                              </DropdownToggle>
                              <DropdownMenu>
                                {/* <DropdownItem header>
                                  Profile
                                </DropdownItem>
                                <DropdownItem divider/> */}
                                {/* <DropdownItem>
                                  My Profile
                                </DropdownItem> */}
                                <DropdownItem onClick={this.logOutUser}>
                                  Sign Out
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </div>
                        </Nav>
                      </Collapse>
                    </Navbar>
                  </>
                ) : (
                  <Navbar color="light">
                    <Collapse navbar>
                      <Nav className="ml-auto margin-top-5" navbar>
                        <>
                          {logoutTabs.map((tab) => {
                            return (
                              <NavItem
                                key={tab.key}
                                className={
                                  tab.key !== "/" &&
                                  window.location.href.indexOf(tab.key) !== -1
                                    ? "active"
                                    : ""
                                }
                                data-key={tab.key}
                                onClick={() => this.tabOutClickHandler(tab.key)}
                              >
                                <Link className="nav-link" to='#'>{tab.name}</Link>
                              </NavItem>
                            );
                          })}
                        </>
                        {sessionStorage.getItem("loginClient") &&
                          this.props.match.path !== "/" &&
                          this.showOuterHeaderLinks() && (
                            <NavItem className="logout">
                              <i
                                className="icon icon-logout pointer"
                                onClick={() => this.logOutUser()}
                              />
                            </NavItem>
                          )}
                      </Nav>
                    </Collapse>
                  </Navbar>
                )}
              </Navbar>
            </Container>
          </header>
        )}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  userLogoutRequest: () => dispatch(userLogoutRequest()),
  clearReducer: () => dispatch(clearReducer()),
  clearAuditOptionsReducer: () => dispatch(clearAuditOptionsReducer())
});

const mapStateToProps = (state) => ({
  isUserLoggedIn: state.login.userLoggedIn,
  auditOptions: state?.auditOptions,
});

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(withRouter(Header)));
