export const AUDIT_STATUS_OPTION = [
  { label: "OPEN", value: "OPEN" },
  { label: "IN-PROGRESS", value: "IN_PROGRESS" },
  { label: "PENDING", value: "PENDING" },
  { label: "CLOSED", value: "CLOSED" },
  { label: "EXAMINER-REVIEW", value: "EXAMINER_REVIEW" },
  { label: "REVIEW-COMPLETE", value: "REVIEW_COMPLETE" }
];

export const AUDIT_STATUS_SEARCH_OPTIONS = [
  { label: "All", value: "ALL" },
  { label: "Open", value: "OPEN" },
  { label: "In Progress", value: "IN_PROGRESS" },
  { label: "Pending", value: "PENDING" },
  { label: "Closed", value: "CLOSED" },
  { label: "Imported", value: "IMPORTED"},
  { label: "Examiner Review", value: "EXAMINER_REVIEW" },
  { label: "Review Complete", value: "REVIEW_COMPLETE" }
]

export const PAYMENT_TYPE_OPTIONS = [
  { label: "NA", value: "NA" },
  { label: "Over Payment", value: "Over Payment" },
  { label: "Under Payment", value: "Under Payment" },
];

export const TYPE_KEY_OPTIONS = [
  { label: "Auditor", value: "AUDITOR" },
  { label: "Examiner", value: "EXAMINER" },
  { label: "Group", value: "GROUP" },
]

export const AUDIT_TYPE_OPTIONS = [
  { label: "Random", value: "Random" },
  { label: "Focused audit", value: "Focused audit" },
  { label: "Client", value: "Client" },
  { label: "High Dollar", value: "High Dollar" },
  { label: "Trainee", value: "Trainee" }
];

export const AUDIT_TYPE_SEARCH_OPTIONS = [
  { label: "All", value: "All" },
  { label: "Random", value: "Random" },
  { label: "Focused audit", value: "Focused audit" },
  { label: "Client", value: "Client" },
  { label: "High Dollar", value: "High Dollar" },
  { label: "Trainee", value: "Trainee" }
];

export const ERROR_TYPE_OPTIONS = [ "NA", "Payment", "Procedural" ];

export const ERROR_STATUS_OPTIONS = [
  { label: "NA", value: "NA" },
  { label: "Agree", value: "Agree" },
  { label: "Disagree", value: "Disagree" }
]

export const REBUTTAL_STATUS_OPTIONS = [
  { label: "NA", value: "NA" },
  { label: "Overturned", value: "Overturned" },
  { label: "Upheld", value: "Upheld" }
]

export const CLIENT_OPTIONS = [
  { label: "Activision Blizzard", value: "ACTBL" },
  { label: "Adjoin", value: "ADJN1" },
  { label: "Affinitiv", value: "AFNTV" },
  { label: "Altoona", value: "0AS34" },
  { label: "American Roller", value: "ARC01" },
  { label: "Atomic", value: "ATMIC" }, 
  { label: "Aumni", value: "AUMNI" }, 
  { label: "Baird", value: "BAIRD" },
  { label: "Boucher", value: "BCHR1" },
  { label: "CBI Security", value: "CBISS" },
  { label: "Centivo", value: "CV4CV" },
  { label: "Charon", value: "CHRON" }, 
  { label: "Continuum", value: "CONTI" },
  { label: "Conn-Weld", value: "CNWLD" },
  { label: "Cone", value: "CONE1" },
  { label: "Curry", value: "CURRY" },
  { label: "DWM Holding Test", value: "DWMHL" },
  { label: "Ellwood", value: "ELLWD" },
  { label: "Farmers & Merchants Bank", value: "FMB01" },
  { label: "Flamingo", value: "FLAPP" },
  { label: "Fortis", value: "FRTIS" },
  { label: "GB Auto", value: "GBAUS" },
  { label: "GFL Environmental", value: "GFL01" },
  { label: "Greene Still", value: "GRNR1" },
  { label: "Greene Inc", value: "Greene Inc" },
  { label: "Guardian Life", value: "GRDLF" },
  { label: "Hong Holdings", value: "HONG1" },
  { label: "Leaders Staffing", value: "LDRST" },
  { label: "Liveview Technologies", value: "LVTCH" },
  { label: "MB Aerospace", value: "MBASP" },
  { label: "MEBCO", value: "MEBCO" },
  { label: "Midway Properties", value: "MDWAY" },
  { label: "Model Linen", value: "MDLLN" },
  { label: "Navajo Express", value: "NAVJO" },
  { label: "Nebraska Furniture Mart", value: "NFMEX" },
  { label: "Net Health", value: "NTHLM" },
  { label: "Northwestern Mutual", value: "NWM01" },
  { label: "Novartis", value: "NVRTS" },
  { label: "Packaging Concepts", value: "PCI01" },
  { label: "Paradise", value: "PRDS1" },
  { label: "Paragon Metals", value: "PRGON" },
  { label: "PepsiCo", value: "PPSCO" },
  { label: "Plunder Design", value: "PLNDR" },
  { label: "Ralph Lauren", value: "RLCRP" },
  { label: "Raymondsville", value: "RISD1" },
  { label: "SAPA Investment Group", value: "SAPA1" },
  { label: "San Patricio", value: "SPC01" },
  { label: "Sendiks", value: "SNDKS" },
  { label: "Shepherd All Star Lanes", value: "SHPRD" },
  { label: "SoNE Health", value: "SONE1" },
  { label: "Starling Auto Group", value: "STARL" },
  { label: "Stew Leonards", value: "STLEN" },
  { label: "Tack 22 Holdings", value: "TCK22" },
  { label: "The Tesani Company", value: "TSANI" },
  { label: "Topix Pharmaceuticals", value: "TOPIX" },
  { label: "Tooling & Equipment International", value: "TEI01" },
  { label: "Trove", value: "TROVE" },
  { label: "United Airlines", value: "UAL01" },
  { label: "Utah Motorsports Campus", value: "UTHMS" },
  { label: "Utah Tank and Trailer", value: "UTHTT" },
  { label: "Weaver Street Market", value: "WSMKT" },
  { label: "Whistic Account Services", value: "WHSTC" },
  { label: "Yokes Foods", value: "YOKES" },
  { label: "Young Supply Company", value: "YNGSP" }
]

export const EXAMINER_OPTIONS = [
  { label: "ABareford", value: "ABareford" },
  { label: "APadilla", value: "APadilla" },
  { label: "ARodriguez", value: "ARodriguez" },
  { label: "BDiFabio", value: "BDiFabio" },
  { label: "CBridges_Provider", value: "CBridges_Provider" },
  { label: "CDing", value: "CDing" },
  { label: "CJolly", value: "CJolly"},
  { label: "CMcConico", value: "CMcConico" },
  { label: "CRogers", value: "CRogers" },
  { label: "DDalfonso", value: "DDalfonso" },
  { label: "DMarkowski", value: "DMarkowski" },
  { label: "DOrtiz", value: "DOrtiz" },
  { label: "EBryant", value: "EBryant" },
  { label: "EDI837", value: "EDI837" },
  { label: "EDIZelis", value: "EDIZelis" },
  { label: "HeXie", value: "HeXie" },
  { label: "HowLi", value: "HowLi" },
  { label: "JFeng", value: "JFeng" },
  { label: "JMiller", value: "JMiller" },
  { label: "JRochard", value: "JRochard" },
  { label: "KHyla", value: "KHyla" },
  { label: "LiZhu", value: "LiZhu" },
  { label: "LKelley", value: "LKelley" },
  { label: "LWatson", value: "LWatson" },
  { label: "LWilliams", value: "LWilliams" },
  { label: "MSmeal", value: "MSmeal" },
  { label: "MHoover", value: "MHoover" },
  { label: "MJernigan", value: "MJernigan" },
  { label: "MRamirez", value: "MRamirez" },
  { label: "MLambrix", value: "MLambrix" },
  { label: "Mschaumloeffel", value: "Mschaumloeffel" },
  { label: "PDemske", value: "PDemske" },
  { label: "PLeJeune", value: "PLeJeune" },
  { label: "RHandler", value: "RHandler" },
  { label: "RWager", value: "RWager" },
  { label: "SaWhiting", value: "SaWhiting" },
  { label: "TGarcia", value: "TGarcia" },
  { label: "TMatteson", value: "TMatteson" },
  { label: "TNosal", value: "TNosal" },
  { label: "System Config", value: "System Config"}
]

export const AUDITOR_OPTIONS = [
  { label: "Ale Rodriguez", value: "Ale Rodriguez" },
  { label: "Becky Spengler", value: "Becky Spengler" },
  { label: "CJ Langford", value: "CJ Langford" },
  { label: "Dawn Cala", value: "Dawn Cala" },
  { label: "John Rochard", value: "John Rochard" },
  { label: "Katrina Hyla", value: "Katrina Hyla" },
  { label: "Kristen Pohl", value: "Kristen Pohl" },
  { label: "Melissa Ramirez", value: "Melissa Ramirez" },
  { label: "Michele Hoover", value: "Michele Hoover" },
  { label: "Paul Wann", value: "Paul Wann" },
  { label: "Tracy Matteson", value: "Tracy Matteson" },
  { label: "Tray Garcia", value: "Tray Garcia" }
]

