import React, { useState } from "react";
import { 
    Modal, 
    ModalBody, 
    ModalHeader,
    Form,
    FormGroup,
    FormFeedback,
    Label,
    Input,
    Row,
    Col
} from 'reactstrap';

import Select from "../../DesignSystem/Select";
import Button from "@atlaskit/button";
import { maskDateMMDDYYYY, formatFinancialNumber, checkValidFinancialFormat, isValidDate, checkSelectObject } from '../../utils/utils';

import "./scss/createClaimModal.scss";
import { AUDIT_TYPE_OPTIONS } from '../../constants/constant.selectOptions';

const initialFormValues = {
    typeKey: 'AuditClaim',
    claimNumber: '',
    client: { label: '', value: '' },
    plan: '',
    subscriberFamilyIdentifier: '',
    employeeId: '',
    dependentId: '',
    patientId: '',
    dependentSequenceNumber: '',
    auditType: { label: '', value: '' },
    examiner: { label: '', value: '' },
    assignee: { label: '', value: '' },
    paymentType: { label: '', value: '' },
    totalCharge: '',
    totalPayment: '',
    claimStatus: '',
    claimIncurredDate: '',
    claimProcessedDate: '',
    claimType: '',
    auditLockDate: ''
}

const initialFormErrorMessages = {
    claimNumber: { isError: false, display: 'Please enter a claim number' },
    typeKey: { isError: false, display: 'Please enter a type key' },
    auditType: { isError: false, display: 'Please enter a audit type value' },
    claimProcessedDate: { isError: false, display: 'Please enter a valid processed date' },
    auditLockDate: { isError: false, display: 'Please enter a valid audit date' }
}

const invalidDateFields = (key, value) => ((key === 'claimProcessedDate' || key === 'auditLockDate') && !isValidDate(value))

export const CreateClaimModal = (props) => {
    const { openModal } = props;
    const [formValues, setFormValues] = useState(initialFormValues);
    const [formErrorMessages, setFormErrorMessages] = useState(initialFormErrorMessages);

    const {
        optionsData: { 
            examiners, 
            auditors, 
            groups 
        }
    } = props.parentProps;

    const handleSubmit = () => {
        let errorsObj = {}
        let formValuesObj = formValues;

        for (const [key, obj] of Object.entries(formValuesObj)) {
            formValuesObj[key] = checkSelectObject(obj)
                ? obj.label 
                : obj;

            if (formErrorMessages[key] && (invalidDateFields(key, formValuesObj[key]) || !formValuesObj[key])) {
                errorsObj = Object.assign(
                    errorsObj,
                    {
                        [key]: { isError: true, display: formErrorMessages[key].display }
                    }
                );
            }
        }

        if (Object.keys(errorsObj).length > 0) {
            return setFormErrorMessages(errorsObj)
        }

        props.submitClaim(formValuesObj)
        return clearAndCloseModal();
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (value || value === '') {
            setFormValues({
                ...formValues,
                [name]: value
            });
        }

        return validateField(name, !!value);
    }

    const handleSelectChange = (e, name) => {
        setFormValues({
            ...formValues,
            [name]: { label: e.label, value: e.value }
        });

        return validateField(name, !!e.value);
    }

    const validateField = (name, value) => {
        if (formErrorMessages[name] && (name !== 'claimProcessedDate' && name !== 'auditLockDate')) {
            setFormErrorMessages({
                ...formErrorMessages,
                [name]: {...formErrorMessages[name], isError: !value}
            });
            
            return !!value;
        } else {
            return true;
        }
    }
    
    const handleDate = (e) => {
        e.target.value = maskDateMMDDYYYY(e.target.value);

        setFormErrorMessages({
            ...formErrorMessages,
            [e.target.name]: {...formErrorMessages[e.target.name], isError: !isValidDate(e.target.value)}
        });

        return handleChange(e);
    }

    const handleFloat = (e) => {
        if (checkValidFinancialFormat(e.target.value)) {
            e.target.value = formatFinancialNumber(e.target.value);
        }

        return handleChange(e);
    }

    const clearAndCloseModal = () => {
        setFormValues(initialFormValues);
        setFormErrorMessages(initialFormErrorMessages);
        return props.closeModal();
    }

    const disableSaveButton = () => {
        if (
            (!checkValidFinancialFormat(formValues.totalCharge) && formValues.totalCharge.length > 0) ||
            (!checkValidFinancialFormat(formValues.totalPayment) && formValues.totalPayment.length > 0) ||
            (!formValues.claimNumber.trim().length > 0)
        ) {
            return true;
        }
        
        return false;
    }

    return (
        <>
            <Modal
                isOpen={openModal}
                toggle={clearAndCloseModal}
                centered={true}
                size="xl"
            >
                <ModalHeader toggle={clearAndCloseModal}> Create Claim </ModalHeader>
                <ModalBody className="modalBodyClaimModal">
                    <Form
                        name="ClaimForm"
                        onSubmit={handleSubmit}
                    >
                        <Col>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label for="claimNumberField">Claim Number</Label>
                                        <Input
                                            name="claimNumber"
                                            id="claimNumberField"
                                            value={formValues.claimNumber}
                                            invalid={formErrorMessages.claimNumber?.isError}
                                            onChange={handleChange}
                                        />
                                        <FormFeedback>
                                            {formErrorMessages.claimNumber?.display}
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="claimProcessedDateField">Processed Date</Label>
                                        <Input
                                            name="claimProcessedDate"
                                            id="claimProcessedDateField"
                                            placeholder="MM-DD-YYYY"
                                            value={formValues.claimProcessedDate}
                                            invalid={formErrorMessages.claimProcessedDate?.isError}
                                            onChange={handleDate}
                                        />
                                        <FormFeedback>
                                            {formErrorMessages.claimProcessedDate?.display}
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="auditLockDateField">Audit Date</Label>
                                        <Input
                                            name="auditLockDate"
                                            id="auditLockDateField"
                                            placeholder="MM-DD-YYYY"
                                            value={formValues.auditLockDate}
                                            invalid={formErrorMessages.auditLockDate?.isError}
                                            onChange={handleDate}
                                        />
                                        <FormFeedback>
                                            {formErrorMessages.auditLockDate?.display}
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label for="auditTypeField">Audit Type</Label>
                                        <Select
                                            name="auditType"
                                            id="auditTypeField"
                                            value={formValues.auditType}
                                            onChange={(value) => handleSelectChange(value, 'auditType')}
                                            options={AUDIT_TYPE_OPTIONS}
                                        />
                                        <Input style={{ display: 'none' }} invalid={formErrorMessages.auditType?.isError}></Input>
                                        <FormFeedback>
                                            {formErrorMessages.auditType?.display}
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="clientField">Client</Label>
                                        <Select
                                            name="client"
                                            id="clientField"
                                            value={formValues.client}
                                            onChange={(value) => handleSelectChange(value, 'client')}
                                            options={groups}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="examinerField">Examiner</Label>
                                        <Select
                                            name="examiner"
                                            id="examinerField"
                                            value={formValues.examiner}
                                            onChange={(value) => handleSelectChange(value, 'examiner')}
                                            options={examiners}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>

                            <Row>
                                <Col sm="4">
                                    <FormGroup>
                                        <Label for="totalChargeField">Total Charge</Label>
                                        <Input
                                            name="totalCharge"
                                            id="totalChargeField"
                                            value={formValues.totalCharge}
                                            invalid={!checkValidFinancialFormat(formValues.totalCharge) && formValues.totalCharge.length > 0}
                                            onChange={handleFloat}
                                        />
                                        <FormFeedback>
                                            Please insert a number with valid financial format
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col sm="4">
                                    <FormGroup>
                                        <Label for="totalPaymentField">Total Payment</Label>
                                        <Input
                                            name="totalPayment"
                                            id="totalPaymentField"
                                            value={formValues.totalPayment}
                                            invalid={!checkValidFinancialFormat(formValues.totalPayment) && formValues.totalPayment.length > 0}
                                            onChange={handleFloat}
                                        />
                                        <FormFeedback>
                                            Please insert a number with valid financial format
                                        </FormFeedback>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="assigneeField">Auditor</Label>
                                        <Select
                                            name="assignee"
                                            id="assigneeField"
                                            value={formValues.assignee}
                                            onChange={(value) => handleSelectChange(value, 'assignee')}
                                            options={auditors}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Col>

                        <Row xs="2" className="confirmButtonsRow">
                            <Col xs="6" className="confirmButtonsCol" style={{ display: "flex", justifyContent: "flex-end"}}>
                                <Button
                                    className="confirmButtons"
                                    isDisabled={disableSaveButton()}
                                    onClick={() => handleSubmit()}
                                >
                                    Confirm
                                </Button>
                            </Col>
                            <Col xs="6" className="confirmButtonsCol" style={{ display: "flex", justifyContent: "flex-start"}}>
                                <Button
                                    className="confirmButtons"
                                    onClick={() => clearAndCloseModal()}
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </Form> 
                </ModalBody>
            </Modal>
        </>
    )
}