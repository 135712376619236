import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import LeftArrow from "@atlaskit/icon/glyph/arrow-left";
import Button from "@atlaskit/button";
import { Input } from "reactstrap";
import "./scss/claimDetail.scss";
import ModalMessage from "../../DesignSystem/ModalMessage";
import { Toast } from "../../DesignSystem/Toast";
import {
  clearSelectedClaim,
  saveClaimCategories,
  updateAllClaimDetails,
  updateCategoryItem,
  getClaimDetail
} from "../../actions/action.getClaims";

import { 
  AUDIT_STATUS_OPTION, 
  AUDIT_TYPE_OPTIONS,
  ERROR_TYPE_OPTIONS,
  ERROR_STATUS_OPTIONS,
  REBUTTAL_STATUS_OPTIONS,
  PAYMENT_TYPE_OPTIONS
} from '../../constants/constant.selectOptions';

import { maskDateMMDDYYYY, formatFinancialNumber, checkValidFinancialFormat, isValidDate, formatStringToComparison, handleText } from "../../utils/utils";
import Layout from "../layout/layout";
import TextField from "../../DesignSystem/TextField";
import { Label } from "../../DesignSystem/FormFields";
import Select from "../../DesignSystem/Select";
import Loader from "../../DesignSystem/Loader";
import HelperMessage from "../../DesignSystem/HelperMessage";
import { CheckBoxComponent } from '../../DesignSystem/CheckBox';
import { CategoriesChecklistComponent } from "./categoriesChecklist"

const errorTypeList = [
  { label: "NO ERROR", value: "NO_ERROR" },
  { label: "PAYMENT", value: "PAYMENT" },
  { label: "PROCEDURAL", value: "PROCEDURAL" }
];

class ClaimDetailComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      claimProcessedDate: this.props.claim?.claimProcessedDate,
      claimNumber: this.props.getClaims?.selectedClaims?.claimNumber || 0,
      claimStatus: this.props.claim?.claimStatus,
      history: this.props.claim.history,
      totalCharge: this.props.claim?.totalCharge,
      totalPayment: this.props.claim?.totalPayment,
      monetaryValue: this.props.claim?.monetaryValue,
      financialAccuracy: this.props.claim?.financialAccuracy,
      auditLockDate: this.props.claim?.auditLockDate,
      notes: handleText(this.props.claim?.notes, true),
      note: "",
      errorMessage: "",
      errorTypeMessage: "",
      paymentError: false,
      monetaryError: false,
      showWarning: false,
      auditStatus: {
        label: this.props.getClaims.selectedClaims.auditStatus,
        value: this.props.getClaims.selectedClaims.auditStatus,
      },
      auditType: {
        label: this.props.claim?.auditType,
        value: this.props.claim?.auditType
      },
      assignee: {
        label: this.props.claim?.assignee,
        value: this.props.claim?.assignee
      },
      client: {
        label: this.props.claim?.client,
        value: this.props.claim?.client,
      },
      examiner: {
        label: this.props.claim?.examiner,
        value: this.props.claim?.examiner
      },
      errorStatus: {
        label: this.props.claim?.errorStatus || "NA",
        value: this.props.claim?.errorStatus || "NA"
      },
      rebuttalStatus: {
        label: this.props.claim?.rebuttalStatus || "NA",
        value: this.props.claim?.rebuttalStatus || "NA"
      },
      paymentType: {
        label: this.props.getClaims?.selectedClaims?.paymentType || "NA",
        value: this.props.getClaims?.selectedClaims?.paymentType || "NA",
      },
      errors: {
        monetory: false,
        financial: false,
        claimProcessedDate: false,
        auditLockDate: false
      },
      errorType: this.props.errorType === "" ? [] : this.props.errorType.toUpperCase().split(',')
    };

    this.initialState = { ...this.state }

    this.scrollRef = React.createRef();
    window.scrollTo(0, 0);
    this.handleBack = this.handleBack.bind(this);
  }

  handleBack = (e) => {
    e.preventDefault();
    this.props.clearSelectedClaim();
  };

  updateAllClaimDetails = (payload) => {
    this.props.updateAllClaimDetails(payload);
  };

  updateCategoryItem = (payload) => {
    this.props.updateCategoryItem(payload);
  }

  getClaimDetail = (payload) => {
    this.props.getClaimDetail(payload);
  }

  updateErrorMessage = () => {
    this.setState({
      ...this.state,
      errorMessage: "",
    });
  };

  handleOnCancel = () => {
    return this.setState(this.initialState);
  }

  handleOnSave = () => {
    let updatedData = {
      typeKey: this.props.claim.typeKey,
      claimProcessedDate: this.state.claimProcessedDate,
      totalCharge: this.state.totalCharge,
      totalPayment: this.state.totalPayment,
      client: this.state.client.value,
      claimStatus: this.state.claimStatus,
      auditType: this.state.auditType.value,
      assignee: this.state.assignee.value,
      examiner: this.state.examiner.value,
      examinerNameReporting: formatStringToComparison(this.state.examiner.value),
      claimNumber: this.state.claimNumber,
      auditStatus: this.state.auditStatus.value,
      systemIdentifier: this.props.claim.claimNumber,
      monetaryValue: this.state.monetaryValue,
      paymentType: this.state.paymentType?.value,
      financialAccuracy: this.state.financialAccuracy,
      errorType: `${this.state.errorType}`,
      errorStatus: this.state.errorStatus.value,
      rebuttalStatus: this.state.rebuttalStatus.value,
      auditLockDate: this.state.auditLockDate,
      notes: handleText(this.state.notes),
      modified: new Date().toISOString()
    }

    return this.updateAllClaimDetails(updatedData);
  }

  handleOnCategoryChange = (category) => {
    let updatedData = {
      systemIdentifier: category.systemIdentifier,
      categoryItemStatus: category.status,
      modified: new Date().toISOString()
    }

    return this.updateCategoryItem(updatedData);
  }

  handleValueChange = (e) => {
    const { name, value } = e.target;
    
    return this.setState({
      ...this.state,
      [name]: value
    });
  };

  handleDate = (e) => {
    e.target.value = maskDateMMDDYYYY(e.target.value);
    this.state.errors[e.target.name] = !isValidDate(e.target.value);

    return this.handleValueChange(e);
  }
  
  handleFloat = (e) => {
    if (checkValidFinancialFormat(e.target.value)) {
      e.target.value = formatFinancialNumber(e.target.value);
    }

    return this.handleValueChange(e);
  }

  selectHandler = (name, value) => {
    return this.setState({ ...this.state, [name]: value });
  };

  cancelHandler = () => {
    for (const [key, value] of Object.entries(this.state.errors)) {
      if (value) {
        return true;
      }
    }
    
    return false;
  };

  disableSaveFormatting = () => {
    if (
      (!checkValidFinancialFormat(this.state.totalCharge) && this.state.totalCharge.length > 0) ||
      (!checkValidFinancialFormat(this.state.totalPayment) && this.state.totalPayment.length > 0) ||
      (!checkValidFinancialFormat(this.state.monetaryValue) && this.state.monetaryValue.length > 0)
    ) {
      return true;
    }
    
    return false;
  }

  render() {
    const {
      claimNumber
    } = this.props.claim;

    const { successToast } = this.props;

    return (
      <Layout>
        <Toast
          isOpen={successToast}
          duration={2000}
          message="Claim edited successfully!"
          onOpenedFunc={() => {}}
        >
        </Toast>

        <ModalMessage
          actionHandler={(status) => this.updateErrorMessage(status)}
          open={!!this.state.errorMessage}
          message={this.state.errorMessage}
          type={this.state.showWarning? "warning": "error"}
        />
        <Loader text="Please wait..." open={this.props.loading} />
        <Row>
          <Col sm={12}>
            <Button
              iconBefore={<LeftArrow label="Star icon" size="small" />}
              appearance="primary"
              className="handleBackBtn"
              onClick={this.handleBack}
            >
              Back
            </Button>
          </Col>
        </Row>
        <Row>
          <Col sm={9} className="leftBox" id="scrollbar">
            <Row className="flexBox">
              <Col sm={3}>
                <Label> Claim Number </Label>
                <TextField
                  value={claimNumber}
                  isReadOnly
                  label="Claim Number"
                />
              </Col>
              <Col sm={3}>
                <Label> Processed Date </Label>
                <TextField 
                  name="claimProcessedDate"
                  value={this.state.claimProcessedDate} 
                  onChange={this.handleDate}
                />
                {this.state.errors.claimProcessedDate && (
                  <HelperMessage error={true}>Please enter a valid processed date</HelperMessage>
                )}
              </Col>
              <Col sm={3}>
                <Label> Audit Date </Label>
                <TextField 
                  name="auditLockDate"
                  value={this.state.auditLockDate} 
                  onChange={this.handleDate}
                />
                {this.state.errors.auditLockDate && (
                  <HelperMessage error={true}>Please enter a valid audit date</HelperMessage>
                )}
              </Col>
              <Col sm={3}>
                <Label> Total Charges </Label>
                <TextField 
                  isAmount 
                  before="$" 
                  name="totalCharge"
                  value={this.state.totalCharge} 
                  onChange={this.handleFloat}
                />
                {!checkValidFinancialFormat(this.state.totalCharge) && (
                  <HelperMessage error={true}>Please insert a number with valid financial format</HelperMessage>
                )}
              </Col>
            </Row>
            <Row className="flexBox">
                <Col sm={3}>
                  <Label> Group </Label>
                  <Select 
                    name="client"
                    value={this.state.client}
                    onChange={(value) => this.selectHandler("client", value)}
                    options={this.props.groups}
                  />
                </Col>
                <Col sm={3}>
                  <Label> Audit Type </Label>
                  <Select 
                    name="auditType"
                    value={this.state.auditType}
                    onChange={(value) => this.selectHandler("auditType", value)}
                    options={AUDIT_TYPE_OPTIONS}
                  />
                </Col>
                <Col sm={3}>
                  <Label> Total Payment </Label>
                  <TextField
                    isAmount
                    before="$"
                    name="totalPayment"
                    value={this.state.totalPayment}
                    onChange={this.handleFloat}
                  />
                  {!checkValidFinancialFormat(this.state.totalPayment) && (
                    <HelperMessage error={true}>Please insert a number with valid financial format</HelperMessage>
                  )}
                </Col>
            </Row>
            <Row className="flexBox" style={{ marginBottom: '40px' }}>
              <Col>
                <Label>Notes</Label>
                <Input 
                  name="notes"
                  type="textarea"
                  value={this.state.notes} 
                  onChange={(value) => this.handleValueChange(value)}
                  style={{ height: '80px'}}
                />
              </Col>
            </Row>
            <Row className="flexBox">
                {
                  this.props.claim.claimCategories.length > 0 ?
                  <CategoriesChecklistComponent 
                    claimCategories={this.props.claim.claimCategories}
                    handleOnCategoryChange={this.handleOnCategoryChange}
                  ></CategoriesChecklistComponent>
                  : 
                  <Row style={{ display: 'flex', justifyContent: 'center', width: '100%', marginLeft: '5%', marginTop: '5%' }}>
                    <Button
                      onClick={() => this.getClaimDetail(claimNumber)}
                    >
                      Reload Checklists
                    </Button>
                  </Row>
                }
            </Row>
          </Col>
          <Col sm={3} className="rightBox">
            <Row className="flexBox">
              <Col>
                <Label>Status</Label>
                <Select
                  onChange={(value) => this.selectHandler("auditStatus", value)}
                  value={this.state.auditStatus}
                  options={AUDIT_STATUS_OPTION}
                />
              </Col>
            </Row>

            <Row className="flexBox">
              <Col>
                <Label>Auditor</Label>
                <Select 
                  onChange={(value) => this.selectHandler("assignee", value)}
                  value={this.state.assignee}
                  options={this.props.auditors}
                />
              </Col>
            </Row>
            
            <Row className="flexBox">
              <Col>
                <Label>Examiner</Label>
                <Select 
                  onChange={(value) => this.selectHandler("examiner", value)}
                  value={this.state.examiner}
                  options={this.props.examiners}
                />
              </Col>
            </Row>

            <Row className="flexBox">
              <Col>
               <Label> Error Type </Label>
                <Row>
                  <CheckBoxComponent
                    valueArray={this.state.errorType}
                    options={ERROR_TYPE_OPTIONS}
                    inline={true}
                    changedValues={(value) => this.selectHandler('errorType', value)}
                    noneValue="NA"
                  >
                  </CheckBoxComponent>
                </Row>
              </Col>
            </Row>

            <Row className="flexBox">
              <Col>
                <Label> Financial Discrepancy </Label>
                <Select
                  name="paymentType"
                  value={this.state.paymentType}
                  onChange={(value) => this.selectHandler("paymentType", value)}
                  options={PAYMENT_TYPE_OPTIONS}
                />
              </Col>
            </Row>
            <Row className="flexBox">
              <Col>
                <Label> Error Status </Label>
                <Select
                  value={this.state.errorStatus}
                  onChange={(value) => this.selectHandler("errorStatus", value)}
                  options={ERROR_STATUS_OPTIONS}
                />
              </Col>
            </Row>
            <Row className="flexBox">
              <Col>
                <Label> Rebuttal Status </Label>
                <Select
                  value={this.state.rebuttalStatus}
                  onChange={(value) => this.selectHandler("rebuttalStatus", value)}
                  options={REBUTTAL_STATUS_OPTIONS}
                />
              </Col>
            </Row>
            <Row className="flexBox">
              <Col>
                <Label>Monetary Value</Label>
                <TextField
                  isAmount
                  before="$"
                  name="monetaryValue"
                  value={this.state.monetaryValue} 
                  onChange={this.handleFloat}
                />
                {!checkValidFinancialFormat(this.state.monetaryValue) && (
                  <HelperMessage error={true}>Please insert a number with valid financial format</HelperMessage>
                )}
              </Col>
            </Row>

            <Row className="flexBox">
              <Col style={{ textAlign: "right", marginTop : 15 }}>
                <Button onClick={() => this.handleOnCancel()}>Cancel</Button>{" "}
                &nbsp;&nbsp;
                <Button
                  onClick={this.handleOnSave}
                  appearance="primary"
                  size="medium"
                  isDisabled={this.cancelHandler() || this.disableSaveFormatting()}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Layout>
    );
  }
}

/**
 * redux mapping of dispatched events to props function
 * @param {object} dispatch
 * @return {object}
 */
const mapDispatchToProps = (dispatch) => ({
  clearSelectedClaim: () => dispatch(clearSelectedClaim()),
  getClaimDetail: (data) => dispatch(getClaimDetail(data)),
  saveClaimCategories: (data) => dispatch(saveClaimCategories(data)),
  updateAllClaimDetails: (data) => dispatch(updateAllClaimDetails(data)),
  updateCategoryItem: (data) => dispatch(updateCategoryItem(data))
});

/**
 * redux mapping of state to props function
 * @param {object} state
 * @return {object}
 */
const mapStateToProps = (state) => {
  return {
    ...state,
    claim: state.getClaims.selectedClaims,
    masterCategoriesLength: state.auditOptions.categoriesSubCategories.length || 0,
    auditors: state.auditOptions.optionsData.auditors,
    groups: state.auditOptions.optionsData.groups,
    examiners: state.auditOptions.optionsData.examiners,
    loading: state.getClaims.loading,
    searchError: state.getClaims.searchError,
    searchFields: state.getClaims.searchField,
    currentUser: state.login,
    errorType: state.getClaims?.selectedClaims?.errorType ?? "NO_ERROR",
    errorTypeList: errorTypeList,
    successToast: state.getClaims.successToast
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ClaimDetailComponent));
