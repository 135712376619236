import React from "react";
import { connect } from "react-redux";
import Layout from "../../components/layout/layout";
import { Alert } from "reactstrap";
import { getAllOptions, addAuditOption } from "../../actions";

import { OptionsComponent } from '../../components/options/options';

import "./index.scss";

function OptionsContainer(props) {

  const { showMessage } = props;

  return (
    <Layout>
      {showMessage && <Alert color="success">{showMessage}</Alert>}
      <OptionsComponent parentProps={props}></OptionsComponent>
    </Layout>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    getAllOptions: () => dispatch(getAllOptions()),
    addAuditOption: () => dispatch(addAuditOption())
  };
};

const mapStateToProps = (state) => {
  return {
    optionsData: state.auditOptions.optionsData,
    optionsDropDownValue: state.auditOptions.optionsDropDownValue,
    loader: state.auditOptions.loader,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OptionsContainer);
