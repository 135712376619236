import * as actionType from "../constants/constant.action";

export const clearAuditOptionsReducer = () => ({
    type: actionType.CLEAR_MASTER_REDUCER
})

// CATEGORY
export const getCategoriesSubCategories = () => ({
    type: actionType.GET_CATEGORIES_SUBCATEGORIES
});

// OPTIONS
export const getAllOptions = (payload) => ({
    type: actionType.GET_ALL_AUDIT_OPTIONS
})

export const getAuditOption = (payload) => ({
    type: actionType.GET_AUDIT_OPTIONS,
    payload
});

export const addAuditOption = (payload) => ({
    type: actionType.ADD_AUDIT_OPTION,
    payload
});