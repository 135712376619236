import * as actionType from "../constants/constant.action";

export const getClaimsBySearch = (payload) => ({
  type: actionType.GET_CLAIMS_REQUESTED,
  payload,
});

export const clearSelectedClaim = () => ({
  type: actionType.CLEAR_SELECTED_CLAIM
});

export const clearReducer = () => ({
  type: actionType.CLEAR_REDUCER,
});

export const getClaimDetail = (payload) => ({
  type: actionType.GET_CLAIM_DETAIL,
  payload,
});

export const saveClaimCategories = (data) => ({
  type: actionType.SAVE_CLAIM_CATEGORIES,
  payload: data
});

export const getSelectedClaimByClaims = (data) => ({
  type: actionType.SELECT_CLAIM_BY_CLAIMS,
  payload: data
})

export const getClaims = () => ({
  type: actionType.GET_CLAIMS_REQUESTED
});

export const clearAllClaims = (payload) => ({
  type: actionType.CLEAR_ALL_CLAIMS,
  payload
});

export const updateErrorMessage = (payload) => ({
  type: actionType.UPDATE_ERROR_MESSAGE,
  payload
});

export const createNewClaim = (payload) => ({
  type: actionType.CREATE_CLAIM,
  payload
});

export const updateAllClaimDetails = (payload) => ({
  type: actionType.UPDATE_ALL_CLAIM_DETAILS,
  payload
});

export const updateCategoryItem = (payload) => ({
  type: actionType.UPDATE_CATEGORY_ITEM,
  payload
})

export const updateApiError = (payload) => ({
  type: actionType.API_ERROR, 
  payload
})

export const openDeleteModal = (payload) => ({
  type: actionType.OPEN_DELETE_MODAL,
  payload
})

export const deleteClaim = (payload) => ({
  type: actionType.DELETE_CLAIM,
  payload
})

export const handleToast = (payload) => ({
  type: actionType.HANDLE_TOAST,
  payload
})

export const parseImportFile = (payload) => ({
  type: actionType.PARSE_IMPORT_FILE,
  payload
})

export const downloadS3Url = (payload) => ({
  type: actionType.DOWNLOAD_S3_URL,
  payload
})

export const importFileClaims = (payload) => ({
  type: actionType.IMPORT_FILE_CLAIMS,
  payload
})

export const clearFileData = () => ({
  type: actionType.CLEAR_FILE_DATA
})
