import React, { useState, useRef, useEffect, Suspense } from "react";
import momentTz from "moment-timezone";
import Button from "@atlaskit/button";
import { EST_TIMEZONE } from "../../constants/constant.app";
import { useDispatch } from "react-redux";
import { Form, FormGroup, Row, Col, Label, Input, FormFeedback } from "reactstrap";
import { getClaimDetail, getClaims, updateErrorMessage, updateApiError, getCategoriesSubCategories, getAllOptions } from "../../actions";

import { ScoringCalculations } from "./scoringCalculations";
import { GetClaimsHeader } from "./getClaimsHeader";
import Grid from "../Grid";
import Tabs from "./FilterTabs";
import Layout from "../layout/layout";
import LoadingOverlay from "../../DesignSystem/Loader";
import ModalMessage from "../../DesignSystem/ModalMessage";
import SignOutModal from "../../DesignSystem/SignOutModal";
import Select from "../../DesignSystem/Select";

// CONSTANTS
import { CLAIM_GRID } from "../../constants/constant.gridColums";
import { AUDIT_STATUS_VALUE } from "../../constants/constant.app";
import { AUDIT_STATUS_SEARCH_OPTIONS, AUDIT_TYPE_SEARCH_OPTIONS } from "../../constants/constant.selectOptions";

import { isValidDate, maskDateMMDDYYYY, isDateInRange, checkSelectObject } from '../../utils/utils'

import "./scss/getClaims.scss";
import { ErrorModal } from "../../DesignSystem/ErrorModal";

const MessageSection = React.lazy(() =>
  import("../../DesignSystem/SectionMessage")
);

const initialSearchFormValues = {
  auditStatus: { label: 'All', value: 'ALL' },
  auditType: { label: '', value: '' },
  assignee: { label: '', value: '' },
  client: { label: '', value: '' },
  examiner: { label: '', value: '' },
  claimProcessStartDate: momentTz().tz(EST_TIMEZONE).subtract(1, "days").format("MM-DD-YYYY"),
  claimProcessEndDate: momentTz().tz(EST_TIMEZONE).format("MM-DD-YYYY"),
  claimNumber: ''
}

function GetClaims(props) {
  const dispatch = useDispatch();
  const {
    claims,
    loading,
    selectedClaims,
    optionsData: { examiners, auditors, groups },
    getClaims : { fetchingText, errorMessage, expiredToken, apiError }
  } = props.parentProps;

  const [clearValues, setClearValues] = useState(false);
  const [searchFormValues, setSearchFormValues] = useState(initialSearchFormValues);
  const [filteredClaims, setFilteredClaims] = useState(claims);
  const [loader, setLoader] = useState(false);
  const messagesEndRef = useRef(null);
  const [tabs, setTabs] = useState({
    showAdvancedSearch: false,
    type: "ALL",
  });

  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
    setInitialClaimList()
  }, [claims]);

  useEffect(() => {  
    dispatch(getClaims());
    dispatch(getCategoriesSubCategories());
    dispatch(getAllOptions());
  }, []);

  useEffect(() => {
    if (claims.length > 0 && !selectedClaims) {
      window.scrollTo(0, window.outerHeight + 500);
    }
  }, [claims, selectedClaims]);

  const setInitialClaimList = () => {
    return setFilteredClaims(claims.filter((claim) => claim.auditStatus.toUpperCase() !== 'CLOSED'))
  }

  const searchByFilter = (type) => {
    setFilteredClaims([]);
    
    switch (type) {
      case 'SEARCH':
        break;
      case 'ALL':
        setInitialClaimList()
        break;
      case 'OTHERS': 
        setFilteredClaims(claims.filter((claim) => claim.auditStatus.toUpperCase() !== 'OPEN' && claim.auditStatus.toUpperCase() !== 'IN_PROGRESS' && claim.auditStatus.toUpperCase() !== 'CLOSED'));
        break;
      default:
        setFilteredClaims(claims.filter((claim) => claim.auditStatus.toUpperCase() === type.toUpperCase()));
        break;
    }
  };

  const tabHandler = (type) => {
    sessionStorage.setItem("selectedTab", type);
    searchByFilter(type);
    setTabs({ ...tabs, showAdvancedSearch: type === "SEARCH", type: type });
  };

  const handleClear = () => {
    setSearchFormValues(initialSearchFormValues);
    setClearValues(true);
  };
  
  const handleClaimSearch = () => {
    let filteredClaims = claims;

    for(const [key, obj] of Object.entries(searchFormValues)) {
      const value = checkSelectObject(obj)
        ? obj.value 
        : obj;

      if (value) {
        filteredClaims = filteredClaims.filter((claim) => {
          if ((key === "auditStatus" || key === "auditType") && value.toUpperCase() === "ALL") {
            return claim[key];
          } else if (key === 'claimProcessStartDate' || key === 'claimProcessEndDate') {
            return isDateInRange(claim.claimProcessedDate, searchFormValues.claimProcessStartDate, searchFormValues.claimProcessEndDate)
          } else {
            return claim[key] === value;
          }
        });
      }
    }

    setClearValues(false);
    setFilteredClaims(filteredClaims);
  };

  const handleClickRow = (record, index, e) => {
    e.stopPropagation();
    e.preventDefault();
    if (record?.auditStatus === AUDIT_STATUS_VALUE.open) {
      dispatch(
        getClaimDetail(record.claimNumber)
      );
    } else {
      dispatch(getClaimDetail(record.claimNumber));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (value || value === '') {
        setSearchFormValues({
            ...searchFormValues,
            [name]: value
        });
    }
  }

  const handleSelectChange = (e, name) => {
      setSearchFormValues({
          ...searchFormValues,
          [name]: { label: e.label, value: e.value }
      });
  }

  const handleDate = (e) => {
    e.target.value = maskDateMMDDYYYY(e.target.value);
    return handleChange(e);
  }

  return (
    <Layout>
      <Row>
        <GetClaimsHeader parentProps={props.parentProps}></GetClaimsHeader>
        <Col>
          <Tabs
            tabHandler={tabHandler}
            claimsData={props?.parentProps?.getClaims}
          />
          <div>&nbsp;</div>
        </Col>
      </Row>

      <ModalMessage
        actionHandler={(status) => dispatch(updateErrorMessage(status))}
        open={!!errorMessage}
        message={errorMessage}
        type="error"
      />

      <SignOutModal
        expiredToken={expiredToken}
      ></SignOutModal>

      <ErrorModal
        apiError={apiError}
        closeError={() => dispatch(updateApiError(''))}
      ></ErrorModal>
      
      <LoadingOverlay open={loading || loader} text={fetchingText} />
      {tabs.showAdvancedSearch && (
        <Form
          className="input-custom border"
          style={{ marginBottom: "35px", padding: "20px" }}
        >
          <Col>
            <Row>
              <Col sm={3}>
                <FormGroup>
                  <Label for="claimProcessStartDateField">Processed Date From</Label>
                  <Input
                    name="claimProcessStartDate"
                    id="claimProcessStartDateField"
                    value={searchFormValues.claimProcessStartDate}
                    onChange={handleDate}
                    invalid={!isValidDate(searchFormValues.claimProcessStartDate)}
                  />
                  <FormFeedback>
                    Please enter a valid date
                  </FormFeedback>
                </FormGroup>
              </Col>

              <Col sm={3}>
                <FormGroup>
                  <Label for="claimProcessEndDateField">Processed Date To</Label>
                  <Input
                    name="claimProcessEndDate"
                    id="claimProcessEndDateField"
                    value={searchFormValues.claimProcessEndDate}
                    onChange={handleDate}
                    invalid={!isValidDate(searchFormValues.claimProcessEndDate)}
                  />
                </FormGroup>
                <FormFeedback>
                    Please enter a valid date
                  </FormFeedback>
              </Col>

              <Col sm={3}>
                <FormGroup>
                  <Label for="claimNumberField">Claim Number</Label>
                  <Input
                    name="claimNumber"
                    id="claimNumberField"
                    value={searchFormValues.claimNumber}
                    onChange={handleChange}
                  />
                </FormGroup>  
              </Col>

              <Col sm={3}>
                <FormGroup>
                  <Label for="auditStatus">Audit Status</Label>
                  <Select
                      name="auditStatus"
                      id="auditStatusField"
                      value={searchFormValues.auditStatus}
                      onChange={(value) => handleSelectChange(value, 'auditStatus')}
                      options={AUDIT_STATUS_SEARCH_OPTIONS}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <FormGroup>
                  <Label for="examinerSearchField">Examiner</Label>
                  <Select 
                    name="examiner"
                    id="examinerField"
                    value={searchFormValues.examiner}
                    onChange={(value) => handleSelectChange(value, 'examiner')}
                    options={examiners}
                  />
                </FormGroup>
              </Col>

              <Col sm={3}>
                <FormGroup>
                  <Label for="assigneeSearchField">Auditor</Label>
                  <Select 
                    name="assignee"
                    id="assigneeField"
                    value={searchFormValues.assignee}
                    onChange={(value) => handleSelectChange(value, 'assignee')}
                    options={auditors}
                  />
                </FormGroup>
              </Col>
              
              <Col sm={3}>
                <FormGroup>
                  <Label for="clientField">Group</Label>
                  <Select 
                    name="client"
                    id="clientField"
                    value={searchFormValues.client}
                    onChange={(value) => handleSelectChange(value, 'client')}
                    options={groups}
                  />
                </FormGroup>
              </Col>

              <Col sm={3}>
                <FormGroup>
                  <Label for="auditTypeField">Audit Type</Label>
                  <Select 
                    name="auditType"
                    id="auditTypeField"
                    value={searchFormValues.auditType}
                    onChange={(value) => handleSelectChange(value, 'auditType')}
                    options={AUDIT_TYPE_SEARCH_OPTIONS}
                  />
                </FormGroup>
              </Col>
            </Row>
            {
              <div className="SearchButtonBox">
                <Button
                  style={{ marginRight: 10 }}
                  onClick={handleClaimSearch}
                  appearance="primary"
                >
                  SEARCH
                </Button>
                <Button onClick={handleClear}>
                  CLEAR
                </Button>
              </div>
            }
          </Col>
        </Form>
      )}

      {filteredClaims.length > 0 ? (
        <div>
          {tabs.showAdvancedSearch &&
            <div>
                <span className="closedMessage"> * Only closed claims are calculated </span>
                <ScoringCalculations
                  claims={filteredClaims}
                  searchValues={searchFormValues}
                  handleClear={clearValues}
                  closedClaims={filteredClaims.filter((claim) => claim.auditStatus.toUpperCase() === 'CLOSED')}
                  isClosedClaims={searchFormValues.auditStatus?.value.toUpperCase() === 'CLOSED'}
                >
                </ScoringCalculations>
            </div>
          }
          <div className="table-container table-responsive search custom-table-container">
            <Grid
              id="claimSearch"
              columns={CLAIM_GRID}
              data={filteredClaims}
              hoverable={true}
              justified={false}
              sortable={true}
              sortOrder={"asc"}
              useFixedHeader={true}
              rowKey={(record) => record.claimNumber}
              clientSidePagination={(claims.length > 0 || filteredClaims.length > 0) ? true : false}
              onRowClick={handleClickRow}
              calendarIcon={null}
            />
            <div ref={messagesEndRef} />
          </div>
        </div>
      ) : (
        <Suspense fallback="Loading...">
          <MessageSection message="No Claim found!" />
        </Suspense>
      )}
    </Layout>
  );
}

export default React.memo(GetClaims);
