import React, { useState } from "react";

import {
    Col,
    Input, 
    Label, 
    Row,
    Modal, 
    ModalBody, 
    ModalHeader,
    Form,
    FormGroup,
} from 'reactstrap';

import Button from "@atlaskit/button";
import Select from "../../DesignSystem/Select";

import { TYPE_KEY_OPTIONS } from '../../constants/constant.selectOptions';
import "./scss/options.scss";

const initialFormValues = {
    typeKey: '',
    label: null,
    value: ''
}

export const AddOptionModal = (props) => {

    const { openModal } = props;
    const [formValues, setFormValues] = useState(initialFormValues);

    const clearAndCloseModal = () => {
        setFormValues(initialFormValues);
        return props.closeModal();
    }

    const handleSelectChange = (e, name) => {
        setFormValues({
            ...formValues,
            [name]: { label: e.label, value: e.value }
        });
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (value || value === '') {
            setFormValues({
                ...formValues,
                [name]: value
            });
        }
    }

    const handleSubmit = () => {
        const submitObj = {
            type: formValues.typeKey.value,
            label: formValues.label ? formValues.label : formValues.value,
            value: formValues.value
        }

        props.submitOption(submitObj);
        return clearAndCloseModal();
    }

    const disableConfirmButton = () => {
        if (
            !formValues.typeKey || 
            !formValues.value ||
            formValues.typeKey?.value === 'GROUP' && (!formValues.value || !formValues.label )
        ) {
            return true;
        }
        
        return false;
    }

    return (
        <>
            <Modal
                isOpen={openModal}
                toggle={() => clearAndCloseModal()}
                centered={true}
                size="ls"
            >
                <ModalHeader toggle={() => clearAndCloseModal()}> Add Option </ModalHeader>
                <ModalBody className="modalBodyClaimModal">
                    <Form
                        name="OptionsForm"
                        onSubmit={handleSubmit}
                    >
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label for="typeKeyField">Option Type</Label>
                                    <Select
                                        name="typeKey"
                                        id="typeKeyField"
                                        value={formValues.typeKey}
                                        onChange={(value) => handleSelectChange(value, 'typeKey')}
                                        options={TYPE_KEY_OPTIONS}
                                    />
                                </FormGroup>
                            </Col>
                            {formValues.typeKey?.value === 'GROUP' ?
                                <div style={{ display: 'flex' }}>
                                    <Col>
                                        <FormGroup>
                                            <Label for="labelField">Group Name</Label>
                                            <Input
                                                name="label"
                                                id="labelField"
                                                value={formValues.label}
                                                onChange={handleChange}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                    <FormGroup>
                                        <Label for="valueField">Group ID</Label>
                                        <Input
                                            name="value"
                                            id="valueField"
                                            value={formValues.value}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                    </Col>
                                </div>
                                : <Col>
                                    <FormGroup>
                                        <Label for="valueField">Value</Label>
                                        <Input
                                            name="value"
                                            id="valueField"
                                            value={formValues.value}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                 </Col>
                            }
                            
                        </Row>

                        <Row xs="2" className="confirmButtonsRow">
                            <Col xs="6" className="confirmButtonsCol" style={{ display: "flex", justifyContent: "flex-end"}}>
                                <Button
                                    className="confirmButtons"
                                    isDisabled={disableConfirmButton()}
                                    onClick={() => handleSubmit()}
                                >
                                    Confirm
                                </Button>
                            </Col>
                            <Col xs="6" className="confirmButtonsCol" style={{ display: "flex", justifyContent: "flex-start"}}>
                                <Button
                                    className="confirmButtons"
                                    onClick={() => clearAndCloseModal()}
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    )
}