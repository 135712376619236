import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { useDispatch } from "react-redux";
import { handleToast } from '../actions'

export const Toast = (props) => {
  const {
    isOpen,
    message,
    duration,
    onOpenedFunc
  } = props;

  const dispatch = useDispatch();

  const handleOnOpened = () => {
    onOpenedFunc();
    setTimeout(() => {
        dispatch(handleToast(false))
      }, duration);
  }

  return (
    <div>
      <Modal 
          isOpen={isOpen}
          size="sm"
          backdrop={false}
          style={{ color: '#FFFFFF' }}
          onOpened={() => handleOnOpened()}
        >
          <ModalBody
            style={{ backgroundColor: 'green' }}
          >
            <div style={{ background: 'green' }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                fill="white"
                className="bi bi-check"
                viewBox="0 0 16 16"
              >
                <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
              </svg>
              {message}
            </div>
          </ModalBody>
        </Modal>
    </div>
  );
}