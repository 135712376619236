import { call, put, takeLatest } from "redux-saga/effects";
import {
  API_ERROR,
  GET_CLAIMS_REQUESTED,
  GET_CLAIMS_SUCCESS,
  GET_CLAIMS_FAILURE,
  UPDATE_FAIL_CLIAM,
  GET_CLAIM_DETAIL,
  UPDATE_CLAIM_DETAIL,
  SAVE_CLAIM_CATEGORIES,
  UPDATE_CLAIM_CATEGORY,
  CREATE_CLAIM,
  UPDATE_ALL_CLAIM_DETAILS,
  UPDATE_ALL_CLAIM_DETAILS_FAILURE,
  UPDATE_CATEGORY_ITEM,
  UPDATE_CATEGORY_ITEM_SUCCESS,
  SESSION_EXPIRED,
  DELETE_CLAIM,
  HANDLE_TOAST,
  PARSE_IMPORT_FILE,
  PARSE_IMPORT_FILE_FAILURE,
  DOWNLOAD_S3_URL,
  DOWNLOAD_S3_URL_SUCCESS,
  DOWNLOAD_S3_URL_FAILURE,
  IMPORT_FILE_CLAIMS,
  IMPORT_FILE_SUCCESS,
  IMPORT_FILE_FAILURE
} from "../constants";

import { api } from "../services";

function* getClaims(action) {
  try {
    const result = yield call(api.getClaims);
    if (result.data && result.status === 200) {
      yield put({
        type: GET_CLAIMS_SUCCESS,
        payload: { result: result.data, searchField: action.payload },
      });
    } else if (result?.status === 401 || (result?.response && result?.response.status === 401)) {
      yield put({
        type: SESSION_EXPIRED
      })
    } else {
      yield put({
        type: API_ERROR,
        payload: result?.response?.data?.error?.message ? result?.response?.data?.error?.message : result?.status ? `Error while fetching the claims. Status code: ${result?.status}` : 'Error while fetching the claims.',
      });
    }
  } catch (error) {
    yield put({ type: API_ERROR, payload: error });
  }
}

function* updateAllClaimDetails(action) {
  const result = yield call(api.updateAllClaimDetails, action.payload);
  if (result.data && result.status === 200) {
    yield put({
      type: GET_CLAIM_DETAIL,
      payload: action?.payload?.systemIdentifier
    });
    yield put({
      type: HANDLE_TOAST,
      payload: true
    })
  } else {
    yield put({
      type: UPDATE_ALL_CLAIM_DETAILS_FAILURE,
      payload: result
    })
  }
}

function* createNewClaim(action) {
  const result = yield call(api.createNewClaim, action.payload);
  if (result.data && result.status === 200) {
    yield put({
      type: GET_CLAIMS_REQUESTED,
      payload: action?.payload
    });
  } else if (result.response && result.response.status.toString() !== '401') {
    yield put({
      type: API_ERROR,
      payload: `It was not possible to create a new claim. Error: ${result.response.status}`
    });
  } else {
    yield put({
      type: SESSION_EXPIRED,
      payload: action?.payload
    })
  };
}

function* updateCategoryItem(action) {
  const result = yield call(api.updateCategoryItem, action.payload);

  if (result.data && result.status === 200) {
    yield put({
      type: UPDATE_CATEGORY_ITEM_SUCCESS,
      payload: action?.payload
    });
  } else {
    yield put({
      type: UPDATE_FAIL_CLIAM,
      payload: result.response.data.error
    })
  }
}

function* getClaimDetail(action) {
  const result = yield call(api.getClaimDetailApi, action.payload)

  if (result.data && result.status === 200) {
    yield put({
      type: UPDATE_CLAIM_DETAIL,
      payload: { result: result.data.Items },
    });
  } else {
    yield put({
      type: UPDATE_FAIL_CLIAM,
      payload: result?.response?.data?.error?.message ? result.response.data.error.message : result.message ? result.message : `Error while fetching claim ${action.payload}`
    });
  }
}

function* updateClaimCategories(action) {
  const result = yield call(api.updateClaimCategories, action.payload);
  if (result.data && result.status === 200) {
    yield put({
      type: UPDATE_CLAIM_CATEGORY,
      payload: { result: action.payload},
    });
  } else {
    yield put({
      type: UPDATE_FAIL_CLIAM,
      payload: result.response.data.error.message,
    });
  }
}

function* deleteClaim(action) {
  const result = yield call(api.deleteClaim, action.payload);
  if (result.status === 200) {
    yield put({
      type: GET_CLAIMS_REQUESTED,
      payload: action?.payload
    });
  } else if (result.status === 401) {
    yield put({
      type: SESSION_EXPIRED,
      payload: action?.payload,
    });
  } else {
    yield put({
      type: API_ERROR,
      payload: `Error while deleting the claim ${action.payload}`
    })
  }
}

function* parseImportFile(action) {
  const result = yield call(api.parseImportFile, action.payload);
  if (result && result.status === 200 && result.data && result.data.resultUrl) {
    yield put({
      type: DOWNLOAD_S3_URL,
      payload: result.data.resultUrl
    });
  } else {
    yield put({
      type: PARSE_IMPORT_FILE_FAILURE,
      payload: result?.response?.data?.error?.message || result.message,
    });
  }
}

function* downloadS3Url(action) {
  const result = yield call(api.downloadS3Url, action.payload);
  if (result.data && result.data.length > 0 && result.status === 200) {
    yield put({
      type: DOWNLOAD_S3_URL_SUCCESS,
      payload: result.data
    });
  } else {
    yield put({
      type: DOWNLOAD_S3_URL_FAILURE,
      payload: result.data && result.data.length <= 0 ? 'Claims list is empty' : result.message,
    });
  }
}

function* importFileClaims(action) {
  const result = yield call(api.importFileClaims, action.payload);
  if (result.status === 200) {
    yield put({
      type: IMPORT_FILE_SUCCESS,
      payload: result.data
    });
  } else {
    yield put({
      type: IMPORT_FILE_FAILURE,
      payload: result?.response?.data?.error?.message ? result?.response?.data?.error?.message : result?.status ? result?.status : 'Error while importing the claims.'
    })
  }
}

export function* claimSearchWatcherSaga() {
  yield takeLatest(GET_CLAIMS_REQUESTED, getClaims);
  yield takeLatest(GET_CLAIM_DETAIL, getClaimDetail);
  yield takeLatest(SAVE_CLAIM_CATEGORIES, updateClaimCategories);
  yield takeLatest(CREATE_CLAIM, createNewClaim);
  yield takeLatest(UPDATE_ALL_CLAIM_DETAILS, updateAllClaimDetails);
  yield takeLatest(UPDATE_CATEGORY_ITEM, updateCategoryItem);
  yield takeLatest(DELETE_CLAIM, deleteClaim);
  yield takeLatest(PARSE_IMPORT_FILE, parseImportFile);
  yield takeLatest(DOWNLOAD_S3_URL, downloadS3Url);
  yield takeLatest(IMPORT_FILE_CLAIMS, importFileClaims);
}
