import React from 'react';
import ReactExport from 'react-export-excel';
import { handleValue, convertStringNumber } from '../../utils/utils';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ExportExcel = ({ memberReportList, calculationResults, startDate, endDate, children }) => {

  memberReportList.forEach((claim) => {
    claim.financialDiscrepancy = handleValue(claim.monetaryValue)
    claim.parsedFinancialDiscrepancy = convertStringNumber(claim.financialDiscrepancy)
    claim.parsedTotalCharge = convertStringNumber(claim.totalCharge)
    claim.parsedTotalPayment = convertStringNumber(claim.totalPayment)
  })

  const hasError = memberReportList.some((col) => col.errorType && (col.errorType.includes('PAYMENT') || col.errorType.includes('PROCEDURAL')))

  return (
    <ExcelFile
      element={children}
      filename={`Audit Report ${startDate} - ${endDate}`}
    >
      <ExcelSheet data={memberReportList} name="Report">
        <ExcelColumn label="Claim Number" value="claimNumber"/>
        <ExcelColumn label="Processed Date" value ="claimProcessedDate"/>
        <ExcelColumn label="Audit Date" value="auditLockDate"/>
        <ExcelColumn label="Group" value="client" />
        <ExcelColumn label="Examiner" value="examiner" />
        <ExcelColumn label="Auditor" value="assignee" />
        <ExcelColumn label="Audit Status" value="auditStatus" />
        <ExcelColumn label="Total Charge" value="parsedTotalCharge" />
        <ExcelColumn label="Total Payment" value="parsedTotalPayment" />
        <ExcelColumn label="Financial Discrepancy" value="parsedFinancialDiscrepancy"/>
        <ExcelColumn label="Error Type" value="errorType"/>
        {hasError ? <ExcelColumn label="Notes" value="notes" /> : <></>}
      </ExcelSheet>
      <ExcelSheet data={calculationResults} name="Calculation Results">
        <ExcelColumn label="Procedural Accuracy (%)" value="proceduralAccuracy" />
        <ExcelColumn label="Payment Accuracy (%)" value="paymentAccuracy" />
        <ExcelColumn label="Financial Accuracy (%)" value="financialAccuracy" />
      </ExcelSheet>
    </ExcelFile>
  );
};

export default ExportExcel;
