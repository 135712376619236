import React, { useEffect, useState } from "react";
import Grid from "../Grid";
import AutoSuggestDropDown from "../autoSuggestDropDown/autoSuggestDropDown";

import {
    Col,
    Container,
    Label,
    Row,
    Button,
} from 'reactstrap';

import { useDispatch } from "react-redux";
import LoadingOverlay from "../../DesignSystem/Loader";

import { addAuditOption, getAllOptions } from "../../actions";
import { AddOptionModal } from './addOptionModal';
import "./scss/options.scss";

const defaultSelectedOptionType = "";

export const OptionsComponent = (props) => {
    
    const dispatch = useDispatch();

    const OPTIONS_GRID = [
        {
          title: "Option Type",
          key: "type",
          render: (value, row, index) => {
            if (row.typeKey) {
              return row.typeKey;
            }
          },
          width: 150,
        },
        {
          title: "Option Value",
          key: "label",
          sortable: true,
          render: (value, row, index) => {
            if (row.label) {
              return row.label;
            }
          },
          width: 400,
        }
    ];

    const {
        optionsData,
        optionsDropDownValue,
        loader
    } = props.parentProps;

    const [openAddOptionModal, setOpenAddOptionModal] = useState(false);
    const [selectedOptionType, setSelectedOptionType] = useState(
        defaultSelectedOptionType
    );

    const [tableData, setTableData] = useState([
        {
          typeKey: '',
          systemIdentifier: '',
          label: '',
          value: ''
        }
    ]);

    const getOptionsValue = (value) => {
        switch(value.toUpperCase()) {
            case 'AUDITOR':
                return optionsData['auditors']
            case 'EXAMINER':
                return optionsData['examiners']
            case 'GROUP':
                return optionsData['groups']
            default: 
                return
        }
    }
 
    const onChangeHandler = (value) => {
        let options = getOptionsValue(value);
        setSelectedOptionType(value);

        if (options) {
            options = options.map((val, index) => {
                options[index] = {
                  typeKey: val.typeKey,
                  systemIdentifier: val.systemIdentifier,
                  label: val.label,
                  value: val.value
                }
      
                return options[index];
            });
        } else {
            options = [
                {
                    typeKey: '',
                    systemIdentifier: '',
                    label: '',
                    value: '',
                }
            ]
        }

        setTableData(options);
    };

    const submitOption = (value) => {
        dispatch(addAuditOption(value))
    }

    useEffect(() => {
        dispatch(getAllOptions());
    }, []);

    useEffect(() => {
        setSelectedOptionType(defaultSelectedOptionType)
    }, [optionsData]);

    return (
        <Container>
            <AddOptionModal
                openModal={openAddOptionModal}
                closeModal={() => setOpenAddOptionModal(false)}
                submitOption={submitOption}
            ></AddOptionModal>
            <Row xs="12">
                <Col xs="12" className="headerOptions">
                    <Row xs="12" style={{ width: '100%', alignItems: 'center' }}>
                        <Col xs="1">
                            <Button appearance="primary" className="optionsButton" onClick={() => setOpenAddOptionModal(true)}>
                                Add Option
                            </Button>
                        </Col>
                        <Col xs="3">
                            <Label className="text-label-options"> Select Option </Label>
                        </Col>
                        <Col xs="4">
                            <AutoSuggestDropDown
                                name="selectedOptionType"
                                suggestionValue={optionsDropDownValue}
                                defaultValue={selectedOptionType}
                                onChangeHandler={(e) => onChangeHandler(e)}
                                hideIcon={true}
                            />
                         </Col>
                    </Row>
                </Col>
            </Row>
            <LoadingOverlay open={loader} text={'Fetching options...'} />
            {
              selectedOptionType !== '' ?
              <Grid
                  id="optionsGrid"
                  columns={OPTIONS_GRID}
                  data={tableData}
                  hoverable={true}
                  justified={false}
                  sortable={true}
                  sortOrder={"asc"}
                  useFixedHeader={true}
                  rowKey={(option) => option.value}
                  clientSidePagination={tableData.length > 10}
                  calendarIcon={null}
              />
              : <Row></Row>
            }
        </Container>
    )
}