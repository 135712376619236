import axios from 'axios';
import { history } from '../helpers';

const jwt = require('jsonwebtoken'),
  jwkToPem = require('jwk-to-pem');

function validateTokenwithPem(pems) {
  const accessToken = sessionStorage.getItem('accessToken')
  const userPool_Id = 'https://cognito-idp.' + process.env.REACT_APP_REGION + '.amazonaws.com/' + process.env.REACT_APP_USER_POOL_ID
  const decodedJWT = jwt.decode(accessToken, { complete: true })
  const kid = decodedJWT.header.kid

  return new Promise((response, reject) => {
    // reject if its not a valid JWT token
    if (!decodedJWT) {
      reject('Not a valid JWT token')
    }

    // reject if ISS is not matching our userPool Id
    if (decodedJWT.payload.iss !== userPool_Id) {
      reject({
        message: 'invalid issuer',
        iss: decodedJWT.payload
      })
    }

    // Reject the jwt if it's not an 'Access Token'
    if (decodedJWT.payload.token_use !== 'access') {
      reject('Not an access token')
    }

    // Get jwtToken `kid` from header
    const pem = pems[kid]
    if (!pem) {
      reject('Invalid access token')
    }

    // verify the signature of the JWT token to ensure its really coming from your User Pool
    jwt.verify(accessToken, pem, { issuer: userPool_Id }, function(err, payload) {
      if (err) {
        reject('Unauthorized signature for this JWT Token')
      } else {
        response(payload)
      }
    })
  })
}

async function handleResponse(response) {
  const pems = {},
    jwt_set = response.data

  if (jwt_set) {
    for (let i = 0; i < jwt_set.keys.length; i++) {
      const jwk = {
        kty: jwt_set.keys[i].kty,
        n: jwt_set.keys[i].n,
        e: jwt_set.keys[i].e
      }
      pems[jwt_set.keys[i].kid] = jwkToPem(jwk)
    }
  }

  return validateTokenwithPem(pems)
    .then((res) => {
      sessionStorage.setItem('userSignedIn', 'true')
      sessionStorage.setItem('username', res?.username)
    })
    .catch((e) => {
      console.error(e)
      // let alerted = sessionStorage.getItem('sessionTimeOut') || ''
      // if (alerted !== 'yes') {
      //   alert('Session Timed Out')
      //   sessionStorage.setItem('sessionTimeOut', 'yes')
      // }
      sessionStorage.clear()
      history.push('/logout')
    })
}

function handleError(e) {
  sessionStorage.clear()
  history.push('/')
}

export function validateToken() {
  const invalidPathname = ['/', '/login', '/unsubscribe', '/admin']
  return new Promise(async (resolve, reject) => {
    const accessToken = sessionStorage.getItem('accessToken')

    if (invalidPathname.indexOf(window.location.pathname) === -1) {
      if (accessToken) {
        await axios
          .get(`https://cognito-idp.${process.env.REACT_APP_REGION}.amazonaws.com/${process.env.REACT_APP_USER_POOL_ID}/.well-known/jwks.json`, { headers: '' })
          .then(async (data) => {
            await handleResponse(data)
              .then(() => resolve(true))
              .catch((e) => reject(e))
          })
          .catch(handleError)
      } else {
        console.error('no access token');
        reject('no access token');
      }
    }
    resolve(true)
  })
}
