import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';

import getClaims from './reducer.getClaims';
import loginReducer from './reducer.login';
import auditOptionsReducer from './reducer.auditOptions';

const app = combineReducers({
  getClaims,
  form: reduxFormReducer,
  login: loginReducer,
  auditOptions: auditOptionsReducer,
});

export default app;
