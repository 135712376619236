import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import Button from "@atlaskit/button";

import Badge from "../../DesignSystem/Badge";

const TabContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const TabItem = styled.div`
 
`;


const StyledButton = styled(Button)`
  margin-left: 12px;
  margin-right: 12px;
  ${(props) =>
    props.appearance === "primary" &&
    css`
      background: #00a79d !important;
    `}

  ${props => props?.disabled && css `
   cursor: no-drop !important;
 `}
`;


export default React.memo( function Tabs(props) {
  const { tabHandler } = props;
  const [selected, setSelected] = useState("ALL");
  const {
    allClaimsCounter,
    inProgressClaimsCount,
    openClaimsCount,
    othersCounter,
    closedClaimsCount
  } = props?.claimsData;

  useEffect(() => {
    if (tabHandler) {
      tabHandler(selected);
    }
  }, [selected]);

  const role = localStorage.getItem('userType');

  return (
    <TabContainer>
      <TabItem key="assignKey">
        <StyledButton
          appearance={selected === "ALL" ? "primary" : ""}
          onClick={() => setSelected("ALL")}
        >
          <Badge type={"number"}>{allClaimsCounter || 0} </Badge> &nbsp;ALL
        </StyledButton>
      </TabItem>
      <TabItem key="openKey">
        <StyledButton
         disabled={role === 'EXAMINER'}
          appearance={selected === "OPEN" ? "primary" : ""}
          onClick={() => role !== 'EXAMINER' ? setSelected("OPEN") : ''}
        >
           <Badge type={"number"}>{openClaimsCount || 0} </Badge> &nbsp;
          OPEN
        </StyledButton>
      </TabItem>
      <TabItem key="inporgressKey">
        {" "}
        <StyledButton
          disabled={role === 'EXAMINER'}
          appearance={selected === "IN_PROGRESS" ? "primary" : ""}
          onClick={() => role !== 'EXAMINER' ? setSelected("IN_PROGRESS") : ''}
        >
           <Badge type={"number"}>{inProgressClaimsCount || 0} </Badge> &nbsp;
          IN-PROGRESS
        </StyledButton>
      </TabItem>
      <TabItem key="pendingKey">
        {" "}
        <StyledButton
          disabled={role === 'EXAMINER'}
          appearance={selected === "OTHERS" ? "primary" : ""}
          onClick={() => role !== 'EXAMINER' ?  setSelected("OTHERS"): '' }
        >
           <Badge type={"number"}>{othersCounter || 0} </Badge> &nbsp;
            OTHERS
        </StyledButton>
      </TabItem>
      <TabItem key="closedKey">
        {" "}
        <StyledButton
          appearance={selected === "CLOSED" ? "primary" : ""}
          onClick={() => setSelected("CLOSED")}
        >
           <Badge type={"number"}>{closedClaimsCount || 0} </Badge> &nbsp;
            CLOSED
        </StyledButton>
      </TabItem>
      <TabItem key="searchtabKey">
        {" "}
        <StyledButton
          appearance={selected === "SEARCH" ? "primary" : ""}
          onClick={() =>  (role !== 'EXAMINER' || role === null) ? setSelected("SEARCH") : ''}
        >
          SEARCH
        </StyledButton>
      </TabItem>
    </TabContainer>
  );
});
