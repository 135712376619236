
export function formatDateStringFromTimestamp(formattedDateString) {
    let currentDate = new Date(+formattedDateString.toString().replace(/\/Date\((\d+)\)\//, '$1'));;
    var dd = currentDate.getDate();
    var mm = currentDate.getMonth() + 1;
    var yyyy = currentDate.getFullYear();
    if (dd < 10) {
      dd = '0' + dd;
    }
    if (mm < 10) {
      mm = '0' + mm;
    }
    return mm + '/' + dd + '/' + yyyy;
}

export function formatDateString(formattedDateString) {
  let currentDate = new Date(formattedDateString);
  var dd = currentDate.getDate();
  var mm = currentDate.getMonth() + 1;
  var yyyy = currentDate.getFullYear();
  if (dd < 10) {
    dd = '0' + dd;
  }
  if (mm < 10) {
    mm = '0' + mm;
  }
  return mm + '/' + dd + '/' + yyyy;
}

export function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export function maskDateYYYYMMDD(value) {
  let maskDate = value.replace(/\D/g,'').slice(0, 10);

  if (maskDate.length > 6) {
    return `${maskDate.slice(0,4)}-${maskDate.slice(4,6)}-${maskDate.slice(6,8)}`;
  }
  else if (maskDate.length > 4) {
    return `${maskDate.slice(0,4)}-${maskDate.slice(4,6)}`;
  }

  return maskDate;
}

export function maskDateMMDDYYYY(value) {
  let maskDate = value.replace(/\D/g, '').slice(0, 10);

  if (maskDate.length > 4) {
    return `${maskDate.slice(0,2)}-${maskDate.slice(2,4)}-${maskDate.slice(4,8)}`;
  }
  else if (maskDate.length > 2) { 
    return `${maskDate.slice(0,2)}-${maskDate.slice(2,4)}`;
  }

  return maskDate;
}

export function isValidDate(dateString) {
  //let datePattern = /^((0[1-9]|[12]\d|3[01])-(0[1-9]|1[0-2])-[12]\d{3})/; // expression for YYYY-MM-DD date
  let datePattern = /^(0[1-9]|1[0-2])-(0[1-9]|1\d|2\d|3[01])-(19|20)\d{2}$/; // expression for MM-DD-YYYY date

  let dateMatchPattern = dateString.match(datePattern);
  if (dateMatchPattern == null) return false;

  let cleanDateString = dateString.replace(/\D/g, ''); 
  let month = parseInt(cleanDateString.substr(0, 2));
  let day = parseInt(cleanDateString.substr(2, 2));
  let year = parseInt(cleanDateString.substr(4, 4));

  let daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Leap Years
  if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) {
      daysInMonth[1] = 29;
  }

  // Month and Day range
  if (month < 1 || month > 12 || day < 1 || day > daysInMonth[month - 1]) {
      return false;
  }

  return true;
}

export function isDateInRange(date, dateFrom, dateTo) {
  const initialDate = new Date(dateFrom);
  const finalDate = new Date(dateTo);
  const checkDate = new Date(date);

  return (checkDate >= initialDate && checkDate <= finalDate);
}

export function formatStringToComparison(str) {
  const formattedString = str.replace(/\s/g, '').toLowerCase();

  return formattedString;
}

export function maskFloat(value) {
  const maskedValue = value.replace(/\D/g, "");

  if (maskedValue.length > 5) {
    const maskCents = `${maskedValue.slice(0, maskedValue.length - 2)}.${maskedValue.slice(maskedValue.length - 2, maskedValue.length)}`;
    const maskHundreds = maskCents.substring(0, maskCents.length - 3);

    return maskHundreds.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + 
      maskCents.substring(maskCents.length - 3, maskCents.length);
  }

  if (maskedValue.length > 2) {
    return `${maskedValue.slice(0, maskedValue.length - 2)},${maskedValue.slice(maskedValue.length - 2, maskedValue.length)}`;
  }

  return Number(maskedValue).toString();
}

export function formatFinancialNumber(value) {
  const intValue = value.slice(0, value.length - 3);
  const decimals = value.slice(value.length - 3, value.length);
  const clearValue = intValue.replace(/\D/g, "");
  const maskedValue = clearValue.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

  return maskedValue + decimals;
}

export function checkValidFinancialFormat(value) {
  if (value.length > 0) {
    return value.slice(value.length - 3, value.length)[0].includes('.');
  }
}

export function convertStringNumber(value) {
  if (value) {
    {
      value = value.replace(/[^\d,.-]/g, '');
      if (/^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d+)?$/.test(value))
      {
          value = value.replace(/,/g, ''); // remove commas
          return parseFloat(value);
      }
      else if (/^-?(?:\d+|\d{1,3}(?:\.\d{3})+)(?:,\d+)?$/.test(value)) // matches #.###,########
      {
          value = value.replace(/\./g, ''); // remove dots
          value = value.replace(/,/g, '.'); // replace comma with dot
          return parseFloat(value);
      }
      else
      {
          value = value.replace(/,/g, ''); // remove commas
          return parseFloat(value);
      }
    }
  } 
  else {
    return 0;
  }
}

export function checkSelectObject(obj) {
  return (obj === Object(obj) && 'value' in obj);
}

export function handleText(text, replaceHTML = false) {
  if (text) {
    return !replaceHTML 
    ? text.replace(new RegExp('\n','g'), '<br>')
    : text.replace(/<br>/g, "\n");
  }
  return ''
}

export function handleValue(value) {
  if (!value || value === '0' || value === '') {
    return 0;
  }
  return value;
}