import React from "react";
import { connect } from "react-redux";
import Layout from "../../components/layout/layout";
import { CategoriesComponent } from "../../components/categories/categories";
import { getCategoriesSubCategories } from "../../actions";
import { Alert } from "reactstrap";

import "./index.scss";

function GetCategories(props) {

  const { showMessage } = props;

  return (
    <Layout>
      {showMessage && <Alert color="success">{showMessage}</Alert>}
        <CategoriesComponent parentProps={props} ></CategoriesComponent>
    </Layout>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCategoriesSubCategories: () => dispatch(getCategoriesSubCategories())
  };
};

const mapStateToProps = (state) => {
  const categoriesSubCategories = state.auditOptions.categoriesSubCategories.map(
    (val) => {
      return {
        ...val,
        editFlag: false,
      };
    }
  );

  return {
    categoriesSubCategories: categoriesSubCategories,
    categoryDropDownValue: state.auditOptions.categoryDropDownValue,
    categoryValues: state.auditOptions.categoryValues,
    showMessage: state.auditOptions.showMessage,
    loader: state.auditOptions.loader,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GetCategories);
