import { baseService } from '../services';
import { urlConstants, uriConstants } from '../constants/constant.uri';
import axios from 'axios';

const BASE_URL = urlConstants.BASE_URL;
const BASE_URL_HANDLER = urlConstants.BASE_URL_HANDLER;
const QUERY = urlConstants.QUERY;
const COMMANDS = urlConstants.COMMANDS;

export const serviceAPI = {
  getClaims,
  createNewUser,
  resetpassword,
  sendAccessCode,
  verifyaccesscode,
  getClaimDetailApi,
  updateClaimActionApi,
  verifyUserEmail,
  getCategoriesSubCategories,
  updateClaimCategories,
  createNewClaim,
  updateAllClaimDetails,
  updateCategoryItem,
  deleteClaim,
  downloadS3Url,
  parseImportFile,
  importFileClaims,
  getAllAuditOptions,
  getAuditOptions,
  addAuditOptions
};

// add it in another file
const getToken = () => {
  const token = sessionStorage.getItem('idToken');
  return `Bearer ${token}`
}

// function getClaims() {
//   const url = BASE_URL + QUERY + uriConstants.CLAIM_SEARCH_URL;
//   return baseService.get(url, '');
// }

function getClaims() {
    const url = BASE_URL_HANDLER + uriConstants.GET_ALL_CLAIMS;
    return baseService.get(url, '');
}

function createNewUser(requestData) {
  const url = BASE_URL+uriConstants.CREATE_USER_URL;
  return baseService.post(url, '',requestData);
}

function sendAccessCode(requestData) {
  const url = BASE_URL+uriConstants.SEND_CODE;
  return baseService.put(url, '',requestData);
}

function verifyaccesscode (requestData) {
  const url = BASE_URL+uriConstants.VERIFY_CODE;
  return baseService.post(url, '',requestData);
}

function resetpassword (requestData) {
  const url = BASE_URL+uriConstants.RESET_PWD;
  return baseService.post(url, '',requestData);
}

function getClaimDetailApi(requestData) {
  const url = BASE_URL+QUERY+uriConstants.CLAIM_DETAIL+'/'+requestData;
  return baseService.get(url, '');
}

function verifyUserEmail(requestData) {
  const url = BASE_URL+uriConstants.VERIFY_USER_EMAIL;
  return baseService.post(url, '',requestData);
}

function getCategoriesSubCategories() {
  const url = BASE_URL+QUERY+uriConstants.GET_CATEGORIES_SUBCATEGORIES;
  return baseService.get(url, '');
}

function updateClaimCategories(requestData) {
  const url = BASE_URL+uriConstants.UPDATE_CLAIM_CATEGORIES;
  return baseService.post(url, '',requestData);
}

function updateClaimActionApi(requestData) {
  const url = BASE_URL+COMMANDS+uriConstants.CLAIM_DETAIL;
  return baseService.post(url, '', requestData);
}

function createNewClaim(payload) {
  const url = BASE_URL + COMMANDS + uriConstants.CREATE_CLAIM;
  return baseService.post(url, '', payload);
}

function updateAllClaimDetails(payload) {
  const url = BASE_URL + COMMANDS + uriConstants.CLAIM_API;
  return baseService.post(url, '', payload);
}

function updateCategoryItem(payload) {
  const url = BASE_URL + COMMANDS + uriConstants.UPDATE_CATEGORY_ITEM;
  return baseService.post(url, '', payload);
}

function deleteClaim(payload) {
  const url = BASE_URL + COMMANDS + uriConstants.DELETE_CLAIM;
  return baseService.delete(url, '', payload);
}

function getAllAuditOptions() {
  const url = BASE_URL_HANDLER + uriConstants.GET_ALL_AUDIT_OPTIONS;
  return baseService.get(url, '')
}

function getAuditOptions(requestData) {
  const url = BASE_URL + QUERY + uriConstants.GET_AUDIT_OPTIONS +'/' +requestData;
  return baseService.get(url, '');
}

function addAuditOptions(payload) {
  const url = BASE_URL_HANDLER + uriConstants.ADD_AUDIT_OPTION;
  return baseService.post(url, '', payload);
}

async function downloadS3Url (url) {
	const response = await axios.get(url);
	return response;
};

/**
 * Parse imported data
*/
async function parseImportFile (payload) {
  const { fileData, fileName, username } = payload;

  try {
    const getPresignedUrlResponse = await axios.get(
      BASE_URL_HANDLER + uriConstants.GET_PRESIGNED_URL,
      {
        headers: {
          Authorization: getToken(),
        },
        params: {
          objectName: fileName
        },
      }
    );
  
    if (getPresignedUrlResponse.status !== 200) {
      return null;
    }
  
    const presignedUrl = getPresignedUrlResponse.data.url;
    const putObjectResponse = await axios.put(presignedUrl, fileData, {
      headers: {
        "Content-Type": "application/octet-stream",
      },
    });
  
    if (putObjectResponse.status !== 200) {
      return null;
    }
  
    const parseFileResponse = await axios.post(
      BASE_URL_HANDLER + uriConstants.PARSE_CLAIMS_FILE,
      {
        fileName: fileName,
        username: username
      },
      {
        headers: { 
          Authorization: getToken() 
        },
      }
    );
  
    return parseFileResponse;
  } catch (error) {
    return error;
  }
};

/**
 * Import file data
 */
async function importFileClaims (payload) {
  const url = BASE_URL_HANDLER + uriConstants.IMPORT_FILE_CLAIMS;
  return baseService.post(url, '', payload);
}
