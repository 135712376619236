import React, { Component } from 'react';
import { 
    Modal, 
    ModalBody, 
    ModalHeader,
    Row
} from 'reactstrap';

import Button from "@atlaskit/button";

export const ErrorModal = (props) => {

    const { apiError } = props;

    const close = () =>  {
        props.closeError();
    }

    return (
        <Modal
            isOpen={!!apiError}
            centered={true}
            size="lg"
        >
            <ModalHeader> Error </ModalHeader>
            <ModalBody>
                <Row style={{ display: 'flex', justifyContent: 'center' }}>
                    { apiError }
                </Row>
                <Row style={{ display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                    <Button onClick={() => close()}>
                        Close
                    </Button>
                </Row>
            </ModalBody>
        </Modal>
    )
}