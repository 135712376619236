import React from 'react';
import { Col, Input, FormGroup, Label } from 'reactstrap';

export const CheckBoxComponent = (props) => {
    const {
        valueArray,
        options,
        inline,
        noneValue
    } = props;

    const checkHandler = (value) => {
        let tempArray = valueArray;

        if (tempArray.includes(value.toUpperCase())) {
          tempArray = tempArray.filter(v => v !== value.toUpperCase());
        }
        else if (value.toUpperCase() !== noneValue.toUpperCase()){
          tempArray.push(value.toUpperCase());
          tempArray = tempArray.filter(v => v !== noneValue.toUpperCase());
        }
        else {
          tempArray = [value.toUpperCase()];
        }
    
        return props.changedValues(tempArray);
    }

    const checkContainsValue = (value) => {
        return valueArray ? 
            valueArray.includes(value.toUpperCase())
            : false
    }

    return (
        <Col>
            {
                options.map((val, index) => {
                    return (
                        <FormGroup check inline={inline} key={val+index}>
                            <Input
                                style={{ height: '1.75rem', width: 'calc(2rem)', borderRadius: '3rem' }}    
                                type="checkbox" 
                                checked={checkContainsValue(val)}
                                onChange={() => checkHandler(val)}
                            />
                            <Label check> 
                            {val} 
                            </Label>
                        </FormGroup>
                    )
                })
            }
        </Col>
    )
}