import React, { useEffect, useState } from "react";
import Grid from "../Grid";
import AutoSuggestDropDown from "../autoSuggestDropDown/autoSuggestDropDown";

import {
    Col, 
    Container,
    Input, 
    Label, 
    Row, 
    Nav, 
    NavLink
} from 'reactstrap';

import { useDispatch } from "react-redux";
import { ClipLoader } from "react-spinners";

import { getCategoriesSubCategories } from "../../actions";

const defaultSelectedCategory = "";

export const CategoriesComponent = (props) => {
    
    const dispatch = useDispatch();

    const CAT_GRID_COMP = [
        {
          title: "Category Name",
          key: "category",
          render: (value, row, index) => {
            if (row.category !== "") {
              return row.category;
            } else {
              return (
                <Input
                  style={{ height: "40px" }}
                  type="text"
                  name="category"
                  id="category"
                  //innerRef={row.subCategoryId === "" ? addCatName : editCatName}
                  defaultValue={row.category === "New Category" ? "" : row.category}
                />
              );
            }
          },
          width: 150,
        },
        {
          title: "Sub Category Name",
          key: "subCategory",
          render: (value, row, index) => {
            if (row.subCategoryId && !row.editFlag) {
              return row.subCategory;
            } else {
              return (
                <Input
                  style={{ height: "40px" }}
                  type="text"
                  name="subCategory"
                  id="subCategory"
                  defaultValue={row.subCategory}
                />
              );
            }
          },
          width: 400,
        },
        {
          title: "Error Type",
          key: "errorType",
          render: (value, row, index) => {
            if (row.subCategoryId && !row.editFlag) {
              return row.errorType;
            } else {
              return (
                <Input
                  type="select"
                  name="select"
                  style={{
                      height: "40px"
                  }}
                  defaultValue={row.errorType}
                >
                  <option>PAYMENT</option>
                  <option>PROCEDURAL</option>
                  <option>NA</option>
                </Input>
              );
            }
          },
          width: 100,
        }
        // {
        //   title: "Action",
        //   key: "Action",
        //   render: (value, row, index) => {
        //     if (row.subCategoryId && !row.editFlag) {
        //       return (
        //         <Nav>
        //           <NavLink
        //             className="categoryLink"
        //             href="#"
        //             title="Edit"
        //             onClick={() => handleClickEditRow(row.subCategoryId)}
        //           >
        //             <img
        //               src="/pencil.png"
        //               alt="Edit"
        //               style={{ marginRight: "10px" }}
        //             />
        //           </NavLink>
        //           <NavLink
        //             className="categoryLink"
        //             href="#"
        //             title="Delete"
        //             onClick={() => handleClickDeleteRow(row)}
        //           >
        //             <img
        //               src="/delete.jpg"
        //               alt="delete"
        //               style={{ height: "30px" }}
        //             />
        //           </NavLink>
        //         </Nav>
        //       );
        //     } else {
        //       return (
        //         <Nav>
        //           <NavLink
        //             className="categoryLink"
        //             href="#"
        //             title="Save"
        //             onClick={() => saveSubCategory(row)}
        //           >
        //             <img src="/save.png" alt="Save" style={{ height: "30px" }} />
        //           </NavLink>
        //           {row.subCategoryId !== "" && (
        //             <NavLink
        //               className="categoryLink cancelIcon"
        //               href="#"
        //               title="Cancel"
        //               onClick={() => handleClickEditRow(row.subCategoryId, false)}
        //             >
        //               X
        //             </NavLink>
        //           )}
        //         </Nav>
        //       );
        //     }
        //   },
        //   width: 100,
        // },
    ];

    const {
        categoryValues,
        categoryDropDownValue,
        categoriesSubCategories,
        showMessage,
        loader
    } = props.parentProps;

    const [loading, setLoader] = useState(false);

    const [selectedCategoryName, setSelectedCategoryName] = useState(
        defaultSelectedCategory
    );

    const [tableData, setTableData] = useState([
        {
          subCategoryId: "",
          subCategory: "",
          category: "",
          claimNumber: "",
          indicator: "NA",
          errorType: "",
          editFlag: false,
        }
    ]);

    const onChangeHandler = (value) => {
        setSelectedCategoryName(value);
        
        const newRow = [];
        if (value === "New Category"){
          newRow.push({
            subCategoryId: "",
            subCategory: "",
            category: "",
            claimNumber: "",
            indicator: "NA",
            errorType: "",
            editFlag: false
          });

          setTableData(newRow);
        } else if (categoryValues.includes(value)) {
          const catSubCatData = getTableData(categoriesSubCategories, value);
          setTableData(catSubCatData);
        }
    };

    const getTableData = (allCatSubCat, selectedCat ) => {
        let data = allCatSubCat.filter(
          (val) => val.categoryType === selectedCat
        );

        data = data.map((val, index) => {
          data[index] = {
            subCategoryId: val.categoryUuid,
            subCategory: val.description,
            category: selectedCat,
            claimNumber: "",
            indicator: "NA",
            errorType: val.errorType,
            editFlag: false,
          }

          return data[index];
        });

        // data.push({
        //   subCategoryId: "",
        //   subCategory: "",
        //   category: selectedCat,
        //   claimNumber: "",
        //   indicator: "NA",
        //   errorType: "",
        //   editFlag: false,
        // });

        return data;
    }

    const handleClickEditRow = (rowIndex, flag = true) => {
        console.log('HANDLE CLICK EDIT ROW', rowIndex);
    };
    
    const handleClickDeleteRow = (saveSubCat) => {
        console.log('HANDLE CLICK DELETE ROW', saveSubCat);
    };

    const saveSubCategory = (saveSubCat) => {
        console.log('SAVE SUB CATEGORY LOG', saveSubCat);
    };

    useEffect(() => {
        dispatch(getCategoriesSubCategories());
    }, [])

    return (
        <Container>
            <Row xs="12">
                <Col
                    xs="12"
                    style={{
                        marginBottom: "4%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                    <Row xs="12" style={{ width: '100%' }}>
                        <Col xs="4">
                            <Label // htmlFor="assignee"
                                className="text-label"
                                style={{ float: "right", marginTop: "10px" }}
                            >
                            Select Category
                            </Label>
                        </Col>
                        <Col xs="4">
                            <AutoSuggestDropDown
                                name="selectedCategoryName"
                                suggestionValue={categoryDropDownValue}
                                defaultValue={selectedCategoryName}
                                onChangeHandler={(e) => onChangeHandler(e)}
                                hideIcon={true}
                            />
                         </Col>
                    </Row>
                </Col>
            </Row>
            <div 
               //</Container> className={`text-center ${loader ? "categories-loader" : ""}`}
            >
                <ClipLoader loading={loading || loader} />
            </div>
            {
              selectedCategoryName !== '' ?
              <Grid
                  id="claimSearch"
                  columns={CAT_GRID_COMP}
                  data={tableData}
                  justified={false}
                  sortable={true}
                  sortOrder={"asc"}
                  useFixedHeader={true}
                  rowKey={(record) => record.subCategoryId}
                  clientSidePagination={tableData.length > 10}
              />
              : <Row></Row>
            }
        </Container>
    )
}