import * as actionType from "../constants/constant.action";
import momentTz from "moment-timezone";
import { EST_TIMEZONE } from "../constants/constant.app";
const defaultState = {
  searchField: {
    auditor: "",
    auditStatus: "OPEN",
    groupName: "",
    examinerName: "",
    claimProcessStartDate: momentTz()
      .tz(EST_TIMEZONE)
      .subtract(1, "days")
      .format("MM/DD/YYYY"),
    claimProcessEndDate: momentTz().tz(EST_TIMEZONE).format("MM/DD/YYYY"),
    claimType: "",
    claimStatus: "",
    greaterThanDollarAmount: "",
    assignee: "",
  },
  loading: false,
  claims: [],
  allClaimsCounter: 0,
  pendingClaimsCount: 0,
  openClaimsCount: 0,
  inProgressClaimsCount: 0,
  closedClaimsCount: 0,
  othersCounter: 0,
  selectedClaims: null,
  actions: [],
  searchAction: false,
  errorMessage: "",
  expiredToken: false,
  apiError: '',
  isDeleteModalOpen: false,
  successToast: false,
  importFeature: {
    isFilesImported: false,
    isFileValid: false,
    importedClaims: [],
  },
  fetchingText: 'Fetching claims...'
};

const getClaims = (state = defaultState, action) => {
  switch (action.type) {
    // FILES
    case actionType.CLEAR_FILE_DATA: 
      return {
        ...state,
        importFeature: {
          importedClaims: [],
          isFileValid: false
        }
      }
    case actionType.DOWNLOAD_S3_URL_SUCCESS:
      return {
        ...state,
        importFeature: {
          importedClaims: action.payload,
          fetchingText: defaultState.fetchingText,
          isFileValid: true
        },
        loading: false
      };

    case actionType.DOWNLOAD_S3_URL_FAILURE: 
      return {
        ...state,
        importFeature: {
          importedClaims: [],
          fetchingText: defaultState.fetchingText,
          isFileValid: false
        },
        loading: false,
        apiError: action.payload
      };

    case actionType.PARSE_IMPORT_FILE:
      return {
        ...state,
        fetchingText: 'Validating imported file...', 
        loading: true
      };

    case actionType.PARSE_IMPORT_FILE_FAILURE:
      return {
        ...state,
        fetchingText: defaultState.fetchingText,
        loading: false,
        apiError: action.payload
      }

    case actionType.IMPORT_FILE_CLAIMS:
      return {
        ...state,
        fetchingText: 'Importing claims...', 
        loading: true
      }

    case actionType.IMPORT_FILE_SUCCESS:
      return {
        ...state,
        importFeature: {
          importedClaims: [],
          isFileValid: false,
          isFilesImported: true
        },
        fetchingText: defaultState.fetchingText,
        loading: false
      }

    case actionType.IMPORT_FILE_FAILURE:
      return {
        ...state,
        fetchingText: defaultState.fetchingText,
        loading: false,
        apiError: action.payload
      }
    
    // CLAIMS
    case actionType.OPEN_DELETE_MODAL:
      return {
        ...state,
        isDeleteModalOpen: action.payload,
        selectedClaims: null,
        loading: false
      };

    case actionType.SESSION_EXPIRED: 
      return {
        ...state,
        expiredToken: true,
        loading: false
      };

    case actionType.API_ERROR:
      return {
        ...state,
        apiError: action.payload,
        expiredToken: false,
        loading: false
      };
    
    case actionType.CREATE_CLAIM:
      return {
        ...state,
        loading: true,
      };
      
    case actionType.CREATE_CLAIM_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: ""
      }

    case actionType.GET_CLAIMS_REQUESTED:
      return {
        ...state,
        loading: true,
      };

    case actionType.GET_CLAIM_DETAIL:
      return {
        ...state,
        loading: !state.isDeleteModalOpen,
      };

    case actionType.GET_CLAIMS_FAILURE:
      return {
        ...state,
        loading: false,
        searchAction: true,
        expiredToken: true
      };

    case actionType.CLEAR_SELECTED_CLAIM:
      return {
        ...state,
        selectedClaims: null,
      };

    case actionType.SAVE_CLAIM_CATEGORIES:
      return {
        ...state,
        loading: true,
      };

    case actionType.UPDATE_CLAIM_DETAIL:
      const claimCategories = [];
      const result = action.payload.result;
      const claimDetails = result.find((i) => i.typeKey === 'AuditClaim');
      
      result.forEach(res => {
        if (res.typeKey === 'AuditClaimCategoryItem') {
          claimCategories.push({
            categoryType: res.categoryType,
            description: res.description,
            errorType: res.errorType,
            categoryUuid: res.categoryUuid,
            status: res.categoryItemStatus,
            systemIdentifier: res.systemIdentifier
          })
        }
      });

      return {
        ...state,
        loading: false,
        selectedClaims: {
          ...claimDetails,
          claimCategories: claimCategories
        }
      };

    case actionType.UPDATE_FAIL_CLIAM: 
      return {
        ...state,
        loading: false,
        apiError: action.payload
      }
    
    case actionType.UPDATE_CATEGORY_ITEM:
      return { 
        ...state,
        loading: true
      }

    case actionType.UPDATE_CATEGORY_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        errorMessage: ""
      }

    case actionType.UPDATE_ALL_CLAIM_DETAILS:
      return {
        ...state,
        loading: true
      }
    
    case actionType.HANDLE_TOAST:
      return {
        ...state,
        successToast: action.payload,
        importFeature: {
          importedClaims: [],
          isFileValid: false,
          isFilesImported: false
        }
      }

    case actionType.SELECT_CLAIM_BY_CLAIMS:
      return {
        ...state,
        selectedClaims: {
          ...action.payload,
          paymentType: null,
          categories: [],
          actions: [],
          errorType: "NO_ERROR",
          detailedCategories: [],
        },
      };

    case actionType.SELECT_CLAIM:
      return {
        ...state,
        selectedClaims: {
          ...action.payload,
        },
      };
      
    case actionType.GET_CLAIMS_SUCCESS_BY_FILTER:
      return {
        ...state,
        ...action.payload,
        searchAction: true,
        loading: false,
        errorMessage: "",
      };

    case actionType.GET_CLAIMS_SUCCESS:
      if (action.payload.result.length === 0) {
        return {
          ...state,
          claims: [],
          searchField: action?.payload?.searchField || [],
          searchError: "Claims not found for given search criteria",
          loading: false,
          searchAction: true,
          errorMessage: "",
          importFeature: {
            importedClaims: [],
            isFileValid: false,
            isFilesImported: false
          },
        };
      }

      return {
        ...state,
        claims: action.payload.result,
        searchField: action?.payload?.searchField || {},
        searchError: "",
        loading: false,
        errorMessage: "",
        allClaimsCounter: action.payload.result.filter((item) => item.auditStatus !== 'CLOSED').length,
        openClaimsCount: action.payload.result.filter((item) => item.auditStatus === 'OPEN').length,
        inProgressClaimsCount: action.payload.result.filter((item) => item.auditStatus === 'IN_PROGRESS').length,
        othersCounter: action.payload.result.filter((item) => item.auditStatus !== 'OPEN' && item.auditStatus !== 'IN_PROGRESS' && item.auditStatus !== 'CLOSED').length,
        closedClaimsCount: action.payload.result.filter((item) => item.auditStatus === 'CLOSED').length
      };

    case actionType.CLEAR_REDUCER:
      return {
        ...defaultState,
      };

    case actionType.CLEAR_ALL_CLAIMS:
      return {
        ...state,
        claims: [],
        searchAction: false,
        errorMessage: "",
      };

    case actionType.UPDATE_CLAIM_CATEGORY:
      const claimData = action.payload.result;
      const updatedClaimsData = state.claims.map((val) => {
        if (val.claimNumber === claimData.claimNumber) {
          val = {
            ...val,
            ...claimData,
          };
        }
        return val;
      });

      return {
        ...state,
        selectedClaims: {
          ...state.selectedClaims,
          ...claimData,
        },
        claims: updatedClaimsData,
        loading: false,
      };

    case actionType.UPDATE_SELECTED_CLAIM:
      const data = action.payload.result;
      const newAction = {
        notes: data.notes,
        assignee: data.assignee || "",
        isAgree: data?.isAgree ?? null,
        timestamp: Date.now(),
        status: data.status,
        user: data.user || localStorage.getItem("login"),
        userRole: data.userType || localStorage.getItem("userType"),
      };
      const updatedData = {
        claimNumber: data?.claimNumber,
        isAgree: data?.isAgree ?? state.selectedClaims?.isAgree,
        assignee: data?.assignee ?? state.selectedClaims?.assignee,
        auditStatus: data?.status ?? state.selectedClaims?.auditStatus,
        paymentType: data?.paymentType ?? state.selectedClaims?.paymentType,
        monetoryValue: data?.monetoryValue ?? state.selectedClaims?.monetoryValue,
        monetaryValue: data?.monetoryValue ?? state.selectedClaims?.monetoryValue,
        financialAccuracy: data?.financialAccuracy ?? state.selectedClaims?.financialAccuracy,
        errorType: data?.errorType ?? state.selectedClaims?.errorType,
      };

      return {
        ...state,
        selectedClaims: {
          ...state.selectedClaims,
          ...updatedData,
          actions: [newAction, ...state.selectedClaims.actions],
        },
        loading : false
      };

    case actionType.UPDATE_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage : ''
      };  
    default:
      return state;
  }
};

export default getClaims;
