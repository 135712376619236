import { all } from "redux-saga/effects";
import { claimSearchWatcherSaga } from "./saga.getClaims";
import { auditOptionsWatcherSaga } from "./saga.auditOptions";

export default function* rootSaga() {
  yield all([
    claimSearchWatcherSaga(),
    auditOptionsWatcherSaga()
  ]);
}
