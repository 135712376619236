import * as actionType from "../constants/constant.action";

const defaultState = {
  categoriesSubCategories: [],
  optionsData: {
    examiners: [],
    auditors: [],
    groups: [],
  },
  optionsDropDownValue: [
    { label: 'Auditor', value: 'Auditor' },
    { label: 'Examiner', value: 'Examiner' },
    { label: 'Group', value: 'Group' }
  ],
  categoryDropDownValue: [],
  categoryValues: [],
  showMessage: "",
  loader: false
};

const auditOptionsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case actionType.CLEAR_MASTER_REDUCER: 
      return {
        ...defaultState,
      }

    case actionType.GET_ALL_AUDIT_OPTIONS:
      return {
        ...state,
        loader: true
      }

    case actionType.GET_ALL_AUDIT_SUCCESS:
      return {
        ...state,
        optionsData: {
          auditors: action?.payload?.result.auditors,
          examiners: action?.payload?.result.examiners,
          groups: action?.payload?.result.groups,
        },
        loader: false
      }
      
    case actionType.GET_ALL_AUDIT_FAILURE:
      return {
        ...state,
        optionsData: defaultState.optionsData,
        loader: false
      }

    case actionType.ADD_AUDIT_OPTION:
      return {
        ...state,
        loader: true
      }

    case actionType.ADD_AUDIT_SUCCESS:
      return {
        ...state,
        loader: false
      }

    case actionType.ADD_AUDIT_FAILURE:
      return {
        ...state,
        loader: false
      }

    case actionType.UPDATE_CATEGORIES_SUBCATEGORIES:
      const resultData = action?.payload?.result.Items || [];

      const categoryValues = [];
      const categoryNames = [];

      resultData.map((val) => {
        if (!categoryValues.includes(val.categoryType)) {
          categoryValues.push(val.categoryType);
          categoryNames.push({ label: val.categoryType, value: val.categoryType });
        }
      });

      return {
        ...state,
        categoriesSubCategories: resultData.map((item) => {
          item.indicator = "NA";
          return item;
        }),
        categoryDropDownValue: categoryNames,
        categoryValues: categoryValues,
      };

    default:
      return state;
  }
};

export default auditOptionsReducer;
