import React, { useEffect, useState } from 'react';
import Button from "@atlaskit/button";

import Grid from "../Grid";
import ExportExcel from "./exportExcel";

import { CALCULATION_GRID } from "../../constants/constant.gridColums";
import { convertStringNumber } from '../../utils/utils';
import './scss/scoringCalculations.scss'

export const ScoringCalculations = (props) => {

    const {
        claims,
        closedClaims,
        handleClear,
        searchValues,
        isClosedClaims
    } = props;

    const [calculationResults, setCalculationResults] = useState([]);

    const handleReportCalculation = () => {
        const proceduralAccuracyClaims = closedClaims.filter((claim) => claim.errorType.includes('PROCEDURAL'));
        const paymentAccuracyClaims = closedClaims.filter((claim) => claim.errorType.includes('PAYMENT'));
        const financialAccuracyOverClaims = closedClaims.filter((claim) => claim.paymentType === 'Over Payment');
        const financialAccuracyUnderClaims = closedClaims.filter((claim) => claim.paymentType === 'Under Payment');

        let totalPaymentClaims = 0;
        closedClaims.forEach(claim => {
            totalPaymentClaims += convertStringNumber(claim.totalPayment);
        });

        let totalFinancialDiscrepancyOver = 0;
        financialAccuracyOverClaims.forEach(claim => {
            totalFinancialDiscrepancyOver += convertStringNumber(claim.monetaryValue);
        })

        let totalFinancialDiscrepancyUnder = 0;
        financialAccuracyUnderClaims.forEach(claim => {
            totalFinancialDiscrepancyUnder += convertStringNumber(claim.monetaryValue);
        })

        setCalculationResults([{
            financialAccuracy: `${
                (((totalPaymentClaims - Math.abs((totalFinancialDiscrepancyOver - totalFinancialDiscrepancyUnder))) / totalPaymentClaims) * 100).toFixed(2)
            }%`,
            paymentAccuracy: `${(
                ((closedClaims.length - paymentAccuracyClaims.length) / (closedClaims.length)) * 100).toFixed(2)
            }%`,
            proceduralAccuracy: `${
                (((closedClaims.length - proceduralAccuracyClaims.length) / (closedClaims.length)) * 100).toFixed(2)
            }%`
        }])
    }

    useEffect(() => {
        if (claims.length > 0) {
            if (isClosedClaims) {
                handleReportCalculation()
            } else {
                setCalculationResults([{
                    financialAccuracy: '',
                    paymentAccuracy: '',
                    proceduralAccuracy: ''
                }])
            }
        }
    }, [claims])

    useEffect(() => {
        if (handleClear) {
            setCalculationResults([])
        }
    }, [handleClear])

    return (
        <div className="scoringContainer">
            <Grid
                id="claimSearchCalculation"
                columns={CALCULATION_GRID}
                data={calculationResults}
                hoverable={true}
                justified={false}
                sortable={false}
                useFixedHeader={true}
                rowKey={(record) => record.financialAccuracy}
                clientSidePagination={false}
                onRowClick={() => {}}
                calendarIcon={null}
            />
            <div
                style={{ display: 'flex', justifyContent: 'center' }}
            >
                <ExportExcel
                    calculationResults={calculationResults} 
                    memberReportList={claims}
                    startDate={searchValues.claimProcessStartDate}
                    endDate={searchValues.claimProcessEndDate}
                >
                    <Button appearance="primary">
                        GENERATE REPORT
                    </Button>
                </ExportExcel>
            </div>
        </div>
    )
}