import React, { useEffect, useState } from 'react'
import { Route } from 'react-router-dom';
import { validateToken } from '../services/service.validateAouthTokens';
import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'
import { getLoginSuccess, sessionExpired } from '../actions'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [signedIn, setSignedIn] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    validateToken()
      .then(setSignedIn)
      .catch((err) => {
        console.error(err);
        history.push("/");
      });
      // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (signedIn) {
      dispatch(getLoginSuccess());
    }
  }, [signedIn, dispatch])

  return (
    <Route
      {...rest}
      render={() => {
        return signedIn ? <Component {...rest} /> : <></>;
      }}
    />
  );
};

export default PrivateRoute;
