import { call, put, takeLatest } from "redux-saga/effects";
import { 
  GET_CATEGORIES_SUBCATEGORIES, 
  UPDATE_CATEGORIES_SUBCATEGORIES, 
  FAIL_CATEGORIES_SUBCATEGORIES,
  GET_ALL_AUDIT_OPTIONS,
  GET_ALL_AUDIT_SUCCESS,
  GET_ALL_AUDIT_FAILURE,
  GET_AUDIT_OPTIONS,
  GET_AUDIT_SUCCESS,
  GET_AUDIT_FAILURE,
  ADD_AUDIT_OPTION,
  ADD_AUDIT_SUCCESS,
  ADD_AUDIT_FAILURE
} from "../constants";

import { api } from "../services";

function* getCategoriesSubCategories(action) {
  try {
    const result = yield call(api.getCategoriesSubCategories, action.payload);
    if (result.data && result.status === 200) {
      yield put({
        type: UPDATE_CATEGORIES_SUBCATEGORIES,
        payload: { result: result.data },
      });
    } else {
      yield put({
        type: FAIL_CATEGORIES_SUBCATEGORIES,
        payload: result.response.data.error.message,
      });
    }
  } catch (error) {
    yield put({ type: FAIL_CATEGORIES_SUBCATEGORIES, error });
  }
}

function* getAllAuditOptions(action) {
  const result = yield call(api.getAllAuditOptions, action.payload);
  if (result.data && result.status === 200) {
    yield put({
      type: GET_ALL_AUDIT_SUCCESS,
      payload: { result: result.data },
    });
  } else {
    yield put({
      type: GET_ALL_AUDIT_FAILURE,
      payload: result,
    });
  }
}

function* getAuditOptions(action) {
  const result = yield call(api.getAuditOptions, action.payload);
  if (result.data && result.status === 200) {
    yield put({
      type: GET_AUDIT_SUCCESS,
      payload: { result: result.data },
    });
  } else {
    yield put({
      type: GET_AUDIT_FAILURE,
      payload: result,
    });
  }
}

function* addAuditOption(action) {
  const result = yield call(api.addAuditOptions, action.payload);
  if (result.status === 200) {
    yield put({
      type: GET_ALL_AUDIT_OPTIONS,
      payload: { result: result.data },
    })
  } else {
    yield put({
      type: ADD_AUDIT_FAILURE,
      payload: result,
    })
  }
}

export function* auditOptionsWatcherSaga() {
  yield takeLatest(GET_CATEGORIES_SUBCATEGORIES, getCategoriesSubCategories);
  yield takeLatest(GET_ALL_AUDIT_OPTIONS, getAllAuditOptions);
  yield takeLatest(GET_AUDIT_OPTIONS, getAuditOptions);
  yield takeLatest(ADD_AUDIT_OPTION, addAuditOption);
}
