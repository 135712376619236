import React, { Component } from "react";
import { Auth } from "@aws-amplify/auth";
import { Loader } from '../../components/wrapper';

class Logout extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.handleLogout();
    }

    handleLogout = () => {
        Auth.signOut({ global: false })
            .then(() => {
                sessionStorage.clear();
                localStorage.clear();
                this.props.history.push("/");
            })
            .catch((error) => {
            console.log('Logout error', error);
            });
    }

    render() {
        return <Loader />;
    }
}

export default Logout;