import React, { useEffect, useState } from "react";
import { 
    Container,
    ListGroup,
    ListGroupItem,
    Col,
    Row
} from "reactstrap";

import RadioButton from './radioButton';
import SelectALLIndicator from "./selectALLIndicator";

import "./scss/categoriesChecklist.scss"

const indicatorValue = {
    "YES": "1", 
    "NONE": "2",
    "NO": "3"
}

const SubCategories = (({categories, handleRadioChange}) => {
    return (
        <ListGroup>
            {categories.map((val, index) => {
                return (
                    <Row key={val.categoryUuid+index}>
                        <Col xs="8">
                            <ListGroupItem
                                color={setSubCategoryStatus(val.status)}
                                className="listGroupItem"
                            >
                            {val.description}
                            </ListGroupItem>
                        </Col>
                        <Col xs="4">
                            <RadioButton
                                id={val.categoryUuid}
                                index={val.categoryUuid}
                                selectedOption={indicatorValue[val.status]}
                                handleChangedValue={handleRadioChange}
                                catData={{ ...val }}
                                key={"radio"+val.categoryUuid}
                            />
                        </Col>
                    </Row>
                )
            })}
        </ListGroup>
    )
})

const setSubCategoryStatus = (e) => {
    switch(e.toUpperCase()) {
        case 'YES':
            return 'success';
        case 'NO': 
            return 'danger';
        case 'NONE':
            return 'info';
        default: 
            return 'info';
    }
}

export const CategoriesChecklistComponent = (props) => {

    const { claimCategories } = props;
    const [categoriesTypeList, setCategoriesTypeList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedSubCategories, setSelectedSubCategories] = useState([]);

    const mountCategories = () => {
        let categories = [];

        claimCategories.forEach(category => {
            if (!categories.includes(category.categoryType)) {
                categories.push(category.categoryType);
            }
        });

        handleCategorySelection(categories[0]);
        return setCategoriesTypeList(categories);
    }

    const handleCategorySelection = (category) => {
        setSelectedCategory(category);
        return handleSubCategories(category);
    }

    const handleSubCategories = (category) => {
        return setSelectedSubCategories(
            claimCategories.filter((claimCategory) => claimCategory.categoryType === category)
        );
    }
    
    const handleRadioChange = (data) => {
        const dataIndex = claimCategories.findIndex((claim) => claim.categoryUuid === data.categoryUuid);
        claimCategories[dataIndex] = dataIndex != -1 
            ? data
            : claimCategories[dataIndex];

        handleSubCategories(selectedCategory);
        return props.handleOnCategoryChange(data);
    }

    const handleSelectAll = (value) => {
        claimCategories.forEach((category, index) => {
            if (category.categoryType === selectedCategory) {
                claimCategories[index].status = value.toUpperCase();
                props.handleOnCategoryChange(claimCategories[index]);
            }
        })

        return handleSubCategories(selectedCategory);
    }

    useEffect(() => {
        if (claimCategories.length > 0) {
            mountCategories();
        }
    }, claimCategories)

    return (
        <Container className="categoriesChecklistContainer">
            <Row className="selectAllIndicatorsRow" style={{ marginLeft: '1%'}}>
                <SelectALLIndicator updateCategoryIndicatorValues={handleSelectAll}></SelectALLIndicator>
            </Row>
            <Row xs="3" className="maxHeight">
                <Col sm={4} className="maxHeight scrollableCol" style={{ paddingLeft: "0px" }}>
                    <ListGroup>
                        {categoriesTypeList.map((category, key) => {
                            return (
                                <ListGroupItem 
                                    key={category+key} 
                                    active={(category === selectedCategory)}
                                    onClick={() => handleCategorySelection(category)}
                                    tag="button"
                                    className="listGroupItem"
                                >
                                    {category}
                                </ListGroupItem>
                            );
                        })}
                    </ListGroup>
                </Col>
                <Col sm={8} className="maxHeight scrollableCol">
                    <SubCategories 
                        categories={selectedSubCategories}
                        handleRadioChange={handleRadioChange}
                    ></SubCategories>
                </Col>
            </Row>
        </Container>
    )
}