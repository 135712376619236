export const GET_LOGIN_REQUEST = 'GET_LOGIN_REQUEST';
export const GET_LOGIN_SUCCESS = 'GET_LOGIN_SUCCESS';
export const GET_LOGIN_FAILURE = 'GET_LOGIN_FAILURE';

export const USER_LOGOUT_REQUEST= 'USER_LOGOUT_REQUEST';

export const GET_CLAIMS_REQUESTED = 'GET_CLAIMS_REQUESTED';
export const GET_CLAIMS_SUCCESS = 'GET_CLAIMS_SUCCESS';
export const GET_CLAIMS_FAILURE = 'GET_CLAIMS_FAILURE';
export const UPDATE_FAIL_CLIAM = "UPDATE_FAIL_CLIAM";
export const SAVE_CLAIM_CATEGORIES = "SAVE_CLAIM_CATEGORIES";
export const UPDATE_CLAIM_CATEGORY = "UPDATE_CLAIM_CATEGORY";
export const SELECT_CLAIM_BY_CLAIMS = "SELECT_CLAIM_BY_CLAIMS";

export const SELECT_CLAIM = "SELECT_CLAIM";
export const CLEAR_SELECTED_CLAIM= "CLEAR_SELECTED_CLAIM";
export const CLEAR_REDUCER = "CLEAR_REDUCER";
export const UPDATE_SELECTED_CLAIM="UPDATE_SELECTED_CLAIM";
export const GET_CLAIM_DETAIL = "GET_CLAIM_DETAIL";
export const UPDATE_CLAIM_DETAIL = "UPDATE_CLAIM_DETAIL";
export const UPDATE_CLAIM_LOCKED = "UPDATE_CLAIM_LOCKED";

// API ERROR
export const API_ERROR = "API_ERROR"
export const HANDLE_TOAST = "HANDLE_TOAST"

// SESSION EXPIRED
export const SESSION_EXPIRED = "SESSION_EXPIRED";

// CREATE CLAIM
export const CREATE_CLAIM = "CREATE_CLAIM";
export const CREATE_CLAIM_SUCCESS = "CREATE_CLAIM_SUCCESS";

// UPDATE ALL CLAIM DETAILS 
export const UPDATE_ALL_CLAIM_DETAILS = "UPDATE_ALL_CLAIM_DETAILS";
export const UPDATE_ALL_CLAIM_DETAILS_SUCCESS = "UPDATE_ALL_CLAIM_DETAILS_SUCCESS";
export const UPDATE_ALL_CLAIM_DETAILS_FAILURE = "UPDATE_ALL_CLAIM_DETAILS_FAILURE";

// DELETE CLAIM
export const OPEN_DELETE_MODAL = "OPEN_DELETE_MODAL"
export const DELETE_CLAIM = "DELETE_CLAIM"

// UPDATE CATEGORY ITEM
export const UPDATE_CATEGORY_ITEM = "UPDATE_CATEGORY_ITEM";
export const UPDATE_CATEGORY_ITEM_SUCCESS = "UPDATE_CATEGORY_ITEM_SUCCESS";

export const GET_CATEGORIES_SUBCATEGORIES = "GET_CATEGORIES_SUBCATEGORIES";
export const UPDATE_CATEGORIES_SUBCATEGORIES = "UPDATE_CATEGORIES_SUBCATEGORIES";
export const FAIL_CATEGORIES_SUBCATEGORIES = "FAIL_CATEGORIES_SUBCATEGORIES";
export const UPDATE_EXAMINER_AUDITOR_NAME = "UPDATE_EXAMINER_AUDITOR_NAME";
export const ADD_SUBCAT = "ADD_SUBCAT";
export const UPDATE_SUB_CAT = "UPDATE_SUB_CAT";
export const UPDATE_SUBCAT_START = "UPDATE_SUBCAT_START";
export const ADD_SUBCAT_START = "ADD_SUBCAT_START";
export const DELETE_START_CAT = 'DELETE_START_SUBCAT';
export const DELETE_SUBCAT_SUCCESS = 'DELETE_SUBCAT_SUCCESS';
export const CLEAR_MASTER_REDUCER = "CLEAR_MASTER_REDUCER";

export const GET_AUDIT_REPORT_REQUEST = 'GET_AUDIT_REPORT_REQUEST';
export const GET_AUDIT_REPORT_SUCCESS = 'GET_AUDIT_REPORT_SUCCESS';
export const GET_AUDIT_REPORT_FAILURE = 'GET_AUDIT_REPORT_FAILURE';
export const CLEAR_AUDIT_REPORT_DETAILS = 'CLEAR_AUDIT_REPORT_DETAILS';
export const GET_CLAIMS_SUCCESS_BY_FILTER = 'GET_CLAIMS_SUCCESS_BY_FILTER';
export const CLEAR_ALL_CLAIMS = 'CLEAR_ALL_CLAIMS';
export const UPDATE_ERROR_MESSAGE = 'UPDATE_ERROR_MESSAGE';

// IMPORT FILES
export const PARSE_IMPORT_FILE = 'PARSE_IMPORT_FILE';
export const PARSE_IMPORT_FILE_SUCCESS = 'PARSE_IMPORT_FILE_SUCCESS';
export const PARSE_IMPORT_FILE_FAILURE = 'PARSE_IMPORT_FILE_FAILURE';
export const DOWNLOAD_S3_URL = 'DOWNLOAD_S3_URL';
export const DOWNLOAD_S3_URL_SUCCESS = 'DOWNLOAD_S3_URL_SUCCESS';
export const DOWNLOAD_S3_URL_FAILURE = 'DOWNLOAD_S3_URL_FAILURE';
export const IMPORT_FILE_CLAIMS = 'IMPORT_FILE_CLAIMS';
export const IMPORT_FILE_SUCCESS = 'IMPORT_FILE_SUCCESS';
export const IMPORT_FILE_FAILURE = 'IMPORT_FILE_FAILURE';
export const CLEAR_FILE_DATA = 'CLEAR_FILE_DATA';

// AUDIT OPTIONS
export const GET_ALL_AUDIT_OPTIONS = 'GET_ALL_AUDIT_OPTIONS';
export const GET_ALL_AUDIT_SUCCESS = 'GET_ALL_AUDIT_SUCCESS';
export const GET_ALL_AUDIT_FAILURE = 'GET_ALL_AUDIT_FAILURE';
export const GET_AUDIT_OPTIONS = 'GET_AUDIT_OPTIONS';
export const GET_AUDIT_SUCCESS = 'GET_AUDIT_SUCCESS';
export const GET_AUDIT_FAILURE = 'GET_AUDIT_FAILURE';
export const ADD_AUDIT_OPTION = 'ADD_AUDIT_OPTION';
export const ADD_AUDIT_SUCCESS = 'ADD_AUDIT_SUCCESS';
export const ADD_AUDIT_FAILURE = 'ADD_AUDIT_FAILURE';