import React from 'react';
import { Button, Row } from 'reactstrap'

import DownloadClaimsTemplate from './downloadTemplate';
import { CreateClaimComponent } from "../createClaim/createClaim";
import { ImportClaims } from "./importClaims";


export const GetClaimsHeader = (props) => {

  return (
    <Row id="getClaimsHeaderRow">
      <CreateClaimComponent parentProps={props.parentProps}></CreateClaimComponent>
      <DownloadClaimsTemplate data={props.parentProps}><Button appearance="primary" className="createClaimButton buttonSize"> Download Template </Button></DownloadClaimsTemplate>
      <ImportClaims parentProps={props.parentProps}></ImportClaims>
    </Row>
 )
}