import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const ConfirmBox = (props) => {
  const {
    isOpen,
    content
  } = props;


  return (
    <div>
      <Modal centered={true} size="md" isOpen={isOpen}>
        <ModalBody>
            {content}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => props.handleOk()}>Yes</Button>{' '}
          <Button color="secondary" onClick={() => props.toggle()}>No</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ConfirmBox;
